
import React from 'react';
import {Link} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import {IconButton, AppBar, Toolbar, Typography} from '@material-ui/core';
import FontAwesome from 'react-fontawesome';

const styles = {
	btn: {
		color: "white"
	}
};

const Header = ({ classes, toggleDrawer }) => {
	return (
		<AppBar position="static">
			<Toolbar>
				<IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={toggleDrawer}>
					<FontAwesome name='bars'/>
				</IconButton>
				<Link to='/' id="link-home">
					<Typography variant="title" className={classes.btn}>
						Dialogg
					</Typography>
				</Link>
			</Toolbar>
		</AppBar>
	);
};

export default withStyles(styles)(Header);
