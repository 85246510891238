
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';
import { Button, Modal, Paper, Slide, Typography } from '@material-ui/core';
import { get, post } from '../Other/api';
import { to } from '../Other/utils';
import { Loading, LoadingButton } from '../Components';
import imgCard from '../assets/images/card.svg';
import imgInvoice from '../assets/images/invoice.svg';

function styles(theme) {
	return {
		backArrow: {
			display: 'flex',
			alignItems: 'center',
			'&:hover': {
				cursor: 'pointer',
			},
		},
		body: {
			display: 'flex',
			flexDirection: 'row',
			left: '50%',
			margin: '0',
			padding: '0',
			position: 'absolute',
			transform: 'translate(-50%, 0)',
			width: '60%'
		},
		header: {
			marginBottom: theme.spacing.unit * 1,
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',
		},
		modal: {
			alignItems: 'center',
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
			display: 'inline-block',
			flexDirection:'column',
			padding: '0',
			position: 'absolute',
			textAlign: 'center',
			width: '50%'
		},
		modalButtonDiv: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-end',
			margin: '0',
			maxWidth: '100%',
			padding: '0'
		},
		modalButtonWidth: { 
			alignSelf: 'flex-end', 
			fontSize: '12px', 
			margin: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px 0`, 
			maxWidth: `calc(50% - ${theme.spacing.unit * 4}px)` 
		},
		modalHr: { 
			margin: '0', 
			padding: '0', 
			width: 'calc(100% - 2px)' 
		},
		modalText: {
			margin: `${theme.spacing.unit * 2}px`
		},
		modalTitle: {
			margin: `${theme.spacing.unit * 2}px`
		},
		paper: {
			display: 'flex',
			flexDirection: 'column',
			margin: `${theme.spacing.unit}px`,
			padding: `${theme.spacing.unit * 3}px`,
			width: `calc(50% - ${theme.spacing.unit * 7}px)`
		},
		paperButton: {
			alignSelf: 'center',
			width: '40%'
		},
		paperImg: {
			alignSelf: 'stretch',
			height: '50%',
			padding: `${theme.spacing.unit}px`,
			width: 'auto'
		},
		paperTitle: {
			margin: '0 0 10px 0'
		},
		root: {
			boxSizing: 'border-box',
			flexGrow: 1,
			margin: 'auto',
			maxWidth: 1200,
			padding: `${theme.spacing.unit * 3}px`,
			width: '100%'
		}
	};
}

function getBlankModal() {
	return { buttons: [], open: false, text: '', title: '' };
}

function getModalStyle() {
    let left = 50, top = 50;
    return {
    	display: 'inline-block',
        left: `${left}%`,
        top: `${top}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

function loadStripe(onLoad) {
	if(!window.StripeCheckout) {
		let script = document.createElement('script');
		script.onload = onLoad;
		script.src = 'https://checkout.stripe.com/checkout.js';
		document.head.appendChild(script);
	}
	else {
		onLoad();
	}
}

// =============================================================== //
// ================= ChargeAccountPage Component ================= //
// =============================================================== //

class ChargeAccountPage extends Component {
	state = {
		actionLoading: true,
		client: {},
		loading: true,
		modal: getBlankModal(),
		paymentMethods: []
	};
	
	chargeStripe = () => {
        this.stripeHandler.open({
            allowRememberMe: false,
			closed: () => { this.setState({ actionLoading: false }); },
            description: `You will be charged ${this.state.client.price}.00/month.`,
            name: 'Dialogg Standard Account',
            panelLabel: 'Upgrade Your Account'
        });
        this.setState({ actionLoading: true, modal: getBlankModal() });
    };
    
    chargeInvoice = async () => {
		this.setState({ actionLoading: true });
    	await this.chargeAccount(null, true);
	}; 
    
    getAccountDetails = async () => {
        let [err, session] = await to(get(`/sessions`));
        if(err) {
			console.log(err);
		}
        if(session.status !== 'success') {
        	return false;
		}
		
        let [clientErr, clientInfo] = await to(get(`/clients/${session.user.client_id}`));
        if(clientErr) {
			console.log(clientErr);
		}
		
		if(clientInfo && clientInfo.client) {
			if(!clientInfo.client.stripe_cid && clientInfo.client.status === "Standard") {
				clientInfo.client.status = "Trial";
			}
			
			this.setState({
				actionLoading: false,
				client: clientInfo.client,
				loading: false,
				paymentMethods: this.getPaymentMethods(clientInfo.client)
			});
		}
    };
    
    getPaymentMethods = (client) => {
    	let paymentMethods = [{ 
			action: this.chargeStripe,
			image: imgCard, 
			imageAlt: 'Credit Card Image', 
			text: 'Your Dialogg subscription will be paid by credit card.\nSubscriptions will auto-renew monthly and can be cancelled at any time.',
			title: 'Pay with Credit Card'
		}];
		if (client.allow_invoice) {
			paymentMethods.push({ 
				action: this.handleModal,
				confirm: this.chargeInvoice,
				data: 'confirm_invoice', 
				image: imgInvoice, 
				imageAlt: 'Invoice Image',
				text: 'Your Dialogg subscription will be added to your ATS invoice.\nSubscriptions are auto-renewed monthly and can be cancelled at any time.',
				title: 'Pay with Invoice'
			});
		}
		return paymentMethods;
	};

    chargeAccount = async (token, useInvoice) => {
        let clientId = (this.state.client ? this.state.client.id : null);
        
        if(clientId) {
			let [err, data] = await to(post(`/clients/${clientId}/${useInvoice ? 'invoices' : 'accounts'}`, { stripeToken: (useInvoice ? null : token.id) }));
			
			if(data && data.status === "success"){
				this.returnToAccount();
			}
			else {
				this.handleModal('error', () => {
					console.log('\nTesting error...', err, data);
					this.setState({ modal: getBlankModal(), actionLoading: false });
				});
			}
		}
		else {
        	console.log('We must have a client_id set to use for charging client.');
		}
    };
	
	handleModal = (mode, action) => {
		let newModal = getBlankModal();
		switch(mode) {
			case 'confirm_invoice':
				newModal.buttons = [
					{ action: this.handleModal, data: null, title: 'Cancel' },
					{ action: action, data: null, title: 'Confirm' }
				];
				newModal.open = true;
				newModal.text = `By selecting "Confirm" below, you will be charged $${this.state.client.price}.00 per month by invoice.\nAre you sure that you want to continue?`;
				newModal.title = 'Confirm Payment Method';
				break;
			case 'error':
				newModal.buttons = [
					{ action: action, data: null, title: 'Acknowledged' }
				];
				newModal.open = true;
				newModal.text = 'An error occurred while attempting to process your payment within our system.\nPlease try again at a later time, and if the error continues to occur, please contact our support team.';
				newModal.title = 'Payment Processing Error';
				break;
			default:
		}
		this.setState({ modal: newModal });
	};
	
	returnToAccount = () => {
		this.props.history.push('/account');
	};
	
// =============================================================== //
// ======================= Lifecycle Hooks ======================= //
// =============================================================== //
	
	componentDidMount() {
        this.getAccountDetails();
        loadStripe(() => {
            this.stripeHandler = window.StripeCheckout.configure({
                key: window.location.host === 'dialogg.io' ? 'pk_live_M2jOUtnON7KEg4AyLNE8IPAR' : 'pk_test_k6J0De8ZZnQbSioTyWOaHi3S',
                locale: 'auto',
                token: async (token) => {
                    this.setState({ actionLoading: true });
                    await this.chargeAccount(token);
                }
            });
        });
    }
    
	render() {
		let { classes } = this.props;
		let fullButtons = ['Acknowledged', 'Confirm'];
		
		return (
			<Fragment>
			{
				this.state.loading ? <Loading /> :	
				<Slide direction="left" in={true} mountOnEnter unmountOnExit >
					<div className={classes.root}>
						<div className={classes.header}>
							<Typography className={classes.backArrow} variant="headline" gutterBottom onClick={this.returnToAccount} >
								<ArrowBack/>
								Return To Account
							</Typography>
						</div>
						<div className={classes.body}>
						{
							this.state.paymentMethods.map((method, m) => {
								return (<Paper className={classes.paper} key={m}>
											<Typography align="center" className={classes.paperTitle} variant="title"><b>{method.title}</b></Typography>
											<img align="center" className={classes.paperImg} src={method.image} alt={method.imageAlt} >
											</img>
											<Typography align="center" className={classes.paperText} component="div" gutterBottom>
												{ method.text.split('\n').map((text, t) => { return <p key={t}>{text}</p>; }) }
											</Typography>
											{
												this.state.actionLoading ? <LoadingButton height={20}/> :
												<Button align="center" className={classes.paperButton} color="primary" disabled={!method.action} onClick={() => { method.action(method.data, method.confirm); }} variant="contained" >
													<Typography style={{ color: 'white' }} variant="title" >Select</Typography>
												</Button>
											}
										</Paper>)
							})
						}
						</div>
						<Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={this.state.modal.open}>
								<div style={getModalStyle()} className={classes.modal}>
									<Typography align="center" className={classes.modalTitle} variant="title" gutterBottom >{this.state.modal.title}</Typography>
									<Typography align="left" className={classes.modalText} component="div" gutterBottom>
										{ this.state.modal.text.split('\n').map((text, t) => { return <p key={t}>{text}</p>; }) }
									</Typography>
									<hr className={classes.modalHr} />
									<div className={classes.modalButtonDiv} >
									{
										this.state.modal.buttons.map((button, b) => {
											return (<Button className={classes['modalButtonWidth']} color="primary" key={`button_${b}`} onClick={() => { button.action(button.data); }} variant={fullButtons.indexOf(button.title) < 0 ? 'outlined' : 'contained'}>
														{button.title}
													</Button>)
										})
									}
									</div>
								</div>
						</Modal>
					</div>
				</Slide>
			}
			</Fragment>
		);
	}
}

export default withRouter(withStyles(styles)(ChargeAccountPage));
