
import React, { Component } from 'react';
import { TableCell, TableRow, IconButton } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import { EditUserDialog } from '../Dialogs';

class UserRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editDialogOpen: false
		};
	}

	openEditModal = () => this.setState({ editDialogOpen: true });

	closeEditModal = () => this.setState({ editDialogOpen: false });

	onSave = () => {
		this.closeEditModal();
		this.props.refreshUsers();
	};

	render() {
		let { info, className } = this.props;
		let { editDialogOpen } = this.state;
		let roles = info.roles.map(({ title }) => title).join(", ");
		
		return (
			<TableRow>
				<TableCell className={className}>
					<IconButton aria-label="Menu" onClick={this.openEditModal}>
						<FontAwesome name="ellipsis-v"/>
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="row">
					{info.first_name} {info.last_name}
				</TableCell>
				<TableCell>{info.email}</TableCell>
				<TableCell>{roles}</TableCell>
				<TableCell>{info.id}</TableCell>
				{editDialogOpen && (
					<EditUserDialog
						user={info}
						open
						onSave={this.onSave}
						onClose={this.closeEditModal}
						userID={info.id}
						userEmail={info.email}
						userPhone={info.phone}
						userRoles={info.roles}
						userFirstName={info.first_name}
						userLastName={info.last_name}
					/>
				)}
			</TableRow>
		);
	}
}

export default withRouter(UserRow)
