
import React from 'react';
import { TableCell, TableRow, IconButton } from '@material-ui/core';
import FontAwesome from 'react-fontawesome';
import { Sound } from './';

const QuestionRow = (props) => {
	let { info, className, openEditModal } = props;
	return (
		<TableRow>
			<TableCell className={className} onClick={openEditModal}>
				<IconButton aria-label="Menu">
					<FontAwesome name='ellipsis-v'/>
				</IconButton>
			</TableCell>
			<TableCell component="th" scope="row">
				{info.title}
			</TableCell>
			<TableCell>
				{info.recording_url ? <Sound url={info.recording_url}/> : null}
			</TableCell>
			<TableCell>{info.alt_text}</TableCell>
			<TableCell>
				{info.labels.length ? info.labels.join(", ") : null}
			</TableCell>
			<TableCell>{info.client_default}</TableCell>
		</TableRow>
	);
};

export default QuestionRow
