
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Paper, TextField, Button } from '@material-ui/core';
import CreatableSelect from 'react-select/lib/Creatable';
import { get } from '../Other/api';

const styles = theme => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		padding: theme.spacing.unit * 2,
		paddingTop: 0,
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		margin: theme.spacing.unit * 2,
	},
	input: {
		display: 'flex',
		padding: 0,
	},
	valueContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		alignItems: 'center',
		overflow: 'hidden',
	},
	noOptionsMessage: {
		padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
	},
	placeholder: {
		position: 'absolute',
		left: 2,
		fontSize: 16,
	},
	question: {
		height: 56,
		margin: theme.spacing.unit * 2,
		display: 'flex',
		justifyContent: 'space-between',
	},
	button: {
		margin: theme.spacing.unit,
	},
	questionsContainer: {
		maxHeight: 500,
		overflow: 'scroll',
	},
	searchResultsTitle: {
		fontSize: 16,
		marginTop: theme.spacing.unit * 2,
		color: '#9D9D9D',
	},
	newQuestionText: {
		marginLeft: theme.spacing.unit,
		color: '#9D9D9D',
	}
});

const NoOptionsMessage = (props) => (
	<Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage}{...props.innerProps}>
		{props.children}
	</Typography>
);

const inputComponent = ({ inputRef, ...props }) => (
	<div ref={inputRef} {...props} />
);

const Control = (props) => (
	<TextField
		fullWidth
		InputProps={{
			inputComponent,
			inputProps: {
				className: props.selectProps.classes.input,
				inputRef: props.innerRef,
				children: props.children,
				...props.innerProps,
			},
		}}
		{...props.selectProps.textFieldProps}
	/>
);

const Option = (props) => (
	<Paper className={props.selectProps.classes.question}{...props.innerProps}>
		<Typography className={props.selectProps.classes.title} variant="title" gutterBottom{...props.innerProps}>
			{props.children}
		</Typography>
		<Button variant="contained" color="primary" className={props.selectProps.classes.button}>
			Select
		</Button>
	</Paper>
);

const Placeholder = (props) => (
	<Typography color="textSecondary" className={props.selectProps.classes.placeholder}{...props.innerProps}>
		{props.children}
	</Typography>
);

const SingleValue = (props) => (
	<Typography className={props.selectProps.classes.title} variant="title" gutterBottom{...props.innerProps}>
		{props.children}
	</Typography>
);

const ValueContainer = (props) => (
	<div className={props.selectProps.classes.valueContainer}>
		{props.children}
	</div>
);

const Menu = (props) => (
	<Fragment>
		<Typography align="center" className={props.selectProps.classes.searchResultsTitle}>
			Search Results
		</Typography>
		<div className={props.selectProps.classes.questionsContainer}{...props.innerProps}>
			{props.children}
		</div>
	</Fragment>
);

const components = {
	Control,
	Menu,
	NoOptionsMessage,
	Option,
	Placeholder,
	SingleValue,
	ValueContainer
};

class QuestionsSearch extends Component {
	state = {
		text: '',
		timer: null,
		questions: [],
	};

	handleTextChange = (event) => {
		this.setState({ text: event.target.value });
		this.debounce(this.getQuestions, 1000);
	};

	handleChange = (item1, item2) => {
		this.props.setQuestion(item1.label, item2.action === 'select-option' ? item1.value : false);
	};

	handleInputChange = (text, item2) => {
		if (item2.action === 'input-blur' || item2.action === 'menu-close') {
			return;
		}
		this.setState({ text });
		this.debounce(this.getQuestions, 1000);
	};

	debounce = (fn, delay) => {
		clearTimeout(this.state.timer);
		let timer = setTimeout(fn, delay);
		this.setState({ timer });
	};

	getQuestions = () => {
		let { text } = this.state;
		this.setState({ error: { status: "processing" }});
		let query = (text ? `?title=${text}` : '');
		
		get(`/clients/questions${query}`).then(json => {
			(json.status === 'success' ? this.handleQuestionsResponse(json.questions) : console.log('error'));
		});
	};

	handleQuestionsResponse = (questions) => {
		let filteredQuestions = questions.filter(({ id }) => { return !this.props.skipIds.includes(id); }).map(({ id, title }) => { return { value: id, label: title }; });
		this.setState({ questions: filteredQuestions });
	};

	componentDidMount() {
		this.getQuestions();
	}

	render() {
		let { classes, theme } = this.props;
		let { questions, text } = this.state;
		let formatCreateLabel = value => (
			<Fragment>
				{value}
				<span className={classes.newQuestionText}>(New Question)</span>
			</Fragment>
		);
		let selectStyles = {
			input: (base) => ({
				...base,
				color: theme.palette.text.primary,
				'& input': {
					font: 'inherit'
				}
			})
		};
		return (
			<CreatableSelect
				classes={classes}
				styles={selectStyles}
				options={questions}
				components={components}
				inputValue={text}
				onChange={this.handleChange}
				onInputChange={this.handleInputChange}
				placeholder="Search Question Library or Type New Question Here"
				formatCreateLabel={formatCreateLabel}
				isClearable
				menuIsOpen
			/>
		);
	}
}

export default withStyles(styles, { withTheme: true })(QuestionsSearch);
