
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Table, TableRow, TableCell, TableHead, TableBody, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, FormControl, Input, FormHelperText, DialogContentText } from '@material-ui/core';
import { get, post } from '../Other/api';
import { to } from '../Other/utils';

const styles = theme => ({
	demoRecordingsTable: {
		width: '100%',
	},
	errorText: {
		marginBottom: theme.spacing.unit
	},
	resultsHeadLabel: {
		fontWeight: 'bold',
		color: '#888888'
	},
	sendDemoActions: {
		display: 'flex'
	},
	viewResultsLink: {
		marginRight: 'auto'
	}
});

const ResultsHead = ({ classes }) => (
	<TableHead>
		<TableRow>
			<TableCell component="th" scope="row">
				<Typography
					variant="body1"
					gutterBottom
					align="left"
					className={classes.resultsHeadLabel}
				>
					Question
				</Typography>
			</TableCell>
			<TableCell>
				<Typography
					variant="body1"
					gutterBottom
					align="left"
					className={classes.resultsHeadLabel}
				>
					Answer
				</Typography>
			</TableCell>
		</TableRow>
	</TableHead>
);

const Result = ({ answer: { title, twilio_recording_url } }) => (
	<TableRow>
		<TableCell component="th" scope="row">
			<Typography
				variant="subheading"
				gutterBottom
				align="left"
			>
				{title}
			</Typography>
		</TableCell>
		<TableCell>
			<audio
				controls
				src={twilio_recording_url}
				type="audio/mp3"
			/>
		</TableCell>
	</TableRow>
);

class DemoInterviewDialog extends Component {
	state = {
		resultsVisible: false,
		fromNumber: '',
		toNumber: '',
		toNumberError: '',
		loginError: '',
		results: null
	};

	componentWillMount() {
		this.getMostRecentResults();
	}

	getMostRecentResults = async () => {
		get(`/demo-calls/answers?interviewId=${this.props.interviewId}`).then(
			({ status, answers }) => {
				if (status === 'success') {
					this.setState({ results: answers });
				}
			},
		);
	};

	initiateDemoInterview = async () => {
		let { interviewId } = this.props;
		let { toNumber } = this.state;
		let [currentUserErr, currentUserResp] = await to(get('/sessions'));
		if (currentUserErr) {
			console.error(currentUserErr);
			return;
		}
		let { user } = currentUserResp;
		if (!user) {
			this.setState({
				loginError: 'You have been logged out and must log in again to use this feature',
			});
			return;
		}
		let phoneRegExp = new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
		if (!phoneRegExp.test(toNumber)) {
			this.setState({ toNumberError: 'Enter a 10 digit phone number' });
			return;
		}
		let [fromNumberErr, fromNumberResp] = await to(get('/calls/number'));
		if (fromNumberErr) {
			console.log(fromNumberErr);
			return;
		}
		let fromNumber = fromNumberResp.number;
		this.setState({ fromNumber });
		let {
			client_id,
			email,
			first_name,
			last_name,
		} = user;
		let [candidateErr, candidate] = await to(post('/demo-calls/initiate', {
			clientId: client_id,
			interviewId,
			callFrom: fromNumber,
			phone: toNumber,
			email,
			firstName: first_name,
			lastName: last_name,
		}));
		if (candidateErr) {
			console.log(candidateErr);
			return;
		}
		let { candidateId } = candidate;
		let intervalId = setInterval(() => {
			get(`/demo-calls/answers/${candidateId}`).then(
				({ status, answers }) => {
					if (status === 'success') {
						this.setState({
							resultsVisible: true,
							results: answers,
							fromNumber: '',
						});
						clearInterval(intervalId);
					}
				},
			);
		}, 3000);
	};

	enterToNumber = ({ target: { value } }) => this.setState({
		toNumber: value,
		toNumberError: '',
	});

	showResults = () => this.setState({ resultsVisible: true });

	hideResults = () => this.setState({ resultsVisible: false });

	render() {
		let { hide, classes } = this.props;
		let {
			results,
			resultsVisible,
			fromNumber,
			toNumber,
			toNumberError,
			loginError
		} = this.state;
		let fromNumberFormatted = fromNumber.replace(/^\+1(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
		return (
			<Dialog
				open
				onClose={hide}
				aria-labelledby="demo-dialog-title"
				aria-describedby="demo-dialog-description"
				maxWidth="md"
				fullWidth={resultsVisible}
			>
				{resultsVisible ? (
					<Fragment>
						<DialogTitle>Results</DialogTitle>
						<DialogContent>
							{results && (
								<Table className={classes.demoRecordingsTable}>
									<ResultsHead classes={classes}/>
									<TableBody>
										{results.map(answer => (
											<Result key={answer.id} answer={{ ...answer }}/>
										))}
									</TableBody>
								</Table>
							)}
						</DialogContent>
						<DialogActions>
							<Button onClick={this.hideResults}>
								Send Again
							</Button>
						</DialogActions>
					</Fragment>
				) : (
					fromNumber ? (
						<Fragment>
							<DialogContent>
								<DialogContentText>
									{`You will now receive a phone call from ${fromNumberFormatted}`}
								</DialogContentText>
							</DialogContent>
						</Fragment>
					) : (
						<Fragment>
							<DialogTitle>Send Demo</DialogTitle>
							<DialogContent>
								<FormControl fullWidth>
									<Input
										placeholder="Send To (xxx-xxx-xxxx)"
										fullWidth
										error={!!toNumberError}
										onChange={this.enterToNumber}
										value={toNumber}
									/>
									<FormHelperText error className={classes.errorText}>
										{toNumberError || loginError}
									</FormHelperText>
								</FormControl>
							</DialogContent>
							<DialogActions className={classes.sendDemoActions}>
								{!!results && (
									<Button
										onClick={this.showResults}
										className={classes.viewResultsLink}
										color="primary"
									>
										View Results
									</Button>
								)}
								<Button
									onClick={this.initiateDemoInterview}
									variant="contained"
									color="primary"
								>
									Send
								</Button>
							</DialogActions>
						</Fragment>
					)
				)}
			</Dialog>
		);
	}
};

export default withStyles(styles)(DemoInterviewDialog);
