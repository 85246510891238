
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button, Modal, Input, Select, MenuItem, FormControl, InputLabel, CircularProgress, FormHelperText } from '@material-ui/core';
import { post, get } from '../Other/api';
import { sortArr } from '../Other/utils';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import CustomInput from '../Components/CustomInput';
import "react-datepicker/dist/react-datepicker.css";

const rpoInvoice = 'rpo_invoice';
const timestampFormat = 'YYYY-MM-DD HH:mm:ss';
function styles(theme) {
	return {
		buttonProgress: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12
		},
		errorText: {
			marginBottom: theme.spacing.unit
		},
		menuItem: {
			textAlign: 'left'
		},
		model: {
			position: 'absolute',
			width: theme.spacing.unit * 50,
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
			padding: theme.spacing.unit * 4,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			textAlign: 'center',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			overflowY: 'scroll',
			maxHeight: '80%'
		},
		selectEmpty: {
			textAlign: 'left',
			marginTop: theme.spacing.unit * 2
		},
		wrapper: {
			margin: theme.spacing.unit,
			position: 'relative'
		}
	};
}

// =============================================================== //
// ================== CreateClientForm Component ================= //
// =============================================================== //

class CreateClientForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			creatingClient: false,
			errors: {},
			fields: [
			{
				key: 'name',
				label: 'Company Name',
				required: true,
				value: ''
			}, {
				key: 'address',
				label: 'Address',
				required: true,
				value: ''
			}, {
				key: 'status',
				label: 'Status',
				opts: [],
				required: true,
				value: ''
			}, {
				key: 'billing_option_id',
				label: '# of Employees',
				opts: [],
				required: true,
				value: ''
			}, {
				key: 'allow_invoice',
				label: 'Allow Invoice Billing',
				opts: [['Yes', 'Yes'], ['No', 'No']],
				required: true,
				value: ''
			}, {
				key: 'sales_rep_user_id',
				label: 'Sales Rep',
				opts: [],
				required: false,
				value: props.salesRepUserId || ''
			}, {
				key: 'expire_date',
				label: 'Expiration Date',
				required: false,
				value: ''
			}, {
				key: 'first_name',
				label: 'Main Contact First Name',
				required: true,
				value: ''
			}, {
				key: 'last_name',
				label: 'Main Contact Last Name',
				required: false,
				value: ''
			}, {
				key: 'email',
				label: 'Main Contact Email',
				required: true,
				value: ''
			}, {
				key: 'phone',
				label: 'Main Contact Phone',
				required: false,
				value: ''
			}, {
				key: 'password',
				label: 'Password',
				required: true,
				value: ''
			}]
		};
	}
	
	loadBillingOptions = () => {
		get('/billing-options', null,
			({ billingOptions }) => this.setState(({ fields }) => ({
				fields: fields.map((field) => (
					field.key === 'billing_option_id' ? { ...field, opts: billingOptions.map(({ id, name }) => [id, name]) } : field
				)),
			})),
		);
	};
	
	loadStatusOptions = () => {
		get('/statuses/clients/active', null,
			({ clientStatuses }) => this.setState(({ fields }) => ({
				fields: fields.map((field) => (
					field.key === "status" ? { ...field, opts: clientStatuses.map(({ id, status, label }) => [status, label]) } : field
				))
			})),
		);
	};

	loadSalesReps = () => {
		get('/users', { roleCode: 'sales' },
			({ users }) => this.setState(({ fields }) => ({
				fields: fields.map((field) => (
					field.key === 'sales_rep_user_id' ? {
						...field,
						opts: [
							[null, 'None'],
							...sortArr(
								users,
								['first_name', 'last_name'],
							).map(({ id, first_name, last_name }) => [id, `${first_name} ${last_name}`]),
						],
					} : field
				))
			})),
		);
	};

	handleChange = (event, key) => {
		let { fields } = this.state;
		let newFields = [];
		fields.forEach((field, f) => {
			field.value = (field.key === key ? event.target.value : field.value);
			newFields.push(field);
		});
		this.setState({ fields: newFields });
	};
	
	handleDateChange = (date, key) => {
		let { fields } = this.state;
		let newFields = [];
		fields.forEach((field, f) => {
			field.value = (field.key === key ? date : field.value);
			newFields.push(field);
		});
		this.setState({ fields: newFields });
	};

	handleSubmit = (event) => {
		event.preventDefault();
		let { fields } = this.state;
		let errors = {};
		let hasErrors = false;
		
		fields.forEach((field) => {
			if (field.required && !field.value) {
				errors[field.key] = 'This field is required';
				hasErrors = true;
			}
		});
		this.setState({ errors });
		if (hasErrors) {
			return false;
		}
		let values = {};
		fields.forEach(({ key, value }) => {
			values[key] = value;
		});
		
		values.allow_invoice = (values.allow_invoice === 'Yes' ? 1 : 0);
		values.expire_date = (values.expire_date ? Moment(values.expire_date).format(timestampFormat) : Moment().add(1, 'months').format(timestampFormat));
		values.is_rpo = (values.status === 'RPO' ? 1 : 0);
		
		if (!values['sales_rep_user_id']) {
			values['sales_rep_user_id'] = null;
		}
		this.setState({ creatingClient: true });
		post('/clients', values, () => {
			return this.props.onClose();
		},
		(postErrors) => {
			let errors = {};
			postErrors.errors.forEach(({ param, msg }) => {
				errors[param] = errors[param] ? `${errors[param]} ${msg}` : msg;
			});
			this.setState({ errors, creatingClient: false });
		});
	};

// =============================================================== //
// ======================= Lifecycle Hooks ======================= //
// =============================================================== //

	componentDidMount() {
		this.loadBillingOptions();
		this.loadStatusOptions();
		this.loadSalesReps();
	}

	render() {
		let { classes, open, onClose } = this.props;
		let { creatingClient, errors, fields } = this.state;
		let formTitle = 'Create Client';
		
		return (
			<Modal
				aria-labelledby={formTitle}
				aria-describedby={formTitle}
				open={open}
				onClose={onClose}
			>
				<div className={classes.model}>
					<Typography variant="display1" gutterBottom>
						{formTitle}
					</Typography>
					<form autoComplete="off" onSubmit={this.handleSubmit} >
					{
						fields.map(({ key, label, opts, value }) => (
							<FormControl key={key} fullWidth>
							{
								key === 'expire_date' ?
								<DatePicker
									customInput={<CustomInput />}
									dateFormat="MMMM d, yyyy h:mm aa"
									disabledKeyboardNavigation
									error={!!errors[key]}
									fullWidth
									key={`${key}_datePicker`}
									minDate={new Date()}
									onChange={(date) => { this.handleDateChange(date, key); }}
									placeholderText={label}
									selected={value}
									shouldCloseOnSelect={true}
									showTimeSelect
									timeFormat="h aa"
									timeIntervals={60}
									timeCaption="Time"
									todayButton={"Today"}
								/> :
								(!opts ? 
								<Input
									error={!!errors[key]}
									fullWidth
									id={`create-client-${key}`}
									key={key}
									onChange={(e) => { this.handleChange(e, key); }}
									placeholder={label}
									value={value || ''}
								/> :
								<Fragment>
								{ !value && (<InputLabel htmlFor={`${key}-simple`}>{label}</InputLabel>) }
									<Select
										className={classes.selectEmpty}
										error={!!errors[key]}
										fullWidth
										inputProps={{ id: `${key}-simple`, name: key }}
										onChange={(e) => { this.handleChange(e, key); }}
										value={value || ''}
									>
									{
										opts.map(([value, label]) => (
										<MenuItem className={classes.menuItem} key={value} value={value} >{label}</MenuItem>))
									}
									</Select>
								</Fragment>)
							}
								<FormHelperText error className={classes.errorText}>
									{errors[key]}
								</FormHelperText>
							</FormControl>)
						)
					}
						<div className={classes.wrapper}>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								fullWidth
								disabled={creatingClient}
							>
								Create Client
							</Button>
							{
								creatingClient && (
								<CircularProgress size={24} className={classes.buttonProgress}/>
							)}
						</div>
					</form>
				</div>
			</Modal>
		);
	}
}

export default withStyles(styles)(CreateClientForm);
