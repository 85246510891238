
import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { blue, yellow, red } from '@material-ui/core/colors';
import { QuestionsPage, ClientsPage, UsersPage, InterviewsPage, EditInterviewPage, TemplateInterviewPage, ClientUsersPage, PasswordPage } from '../Pages';
import { ForgotPassword, Header, LogInForm, TFAValidation, TFASelection } from '../Components';
import SideBar from './SideBar';
import { post, resetPassword, authorizeWithToken } from '../Other/api';
import loaderImg from '../assets/images/dribble-loader.gif';
import { Redirect } from 'react-router';
import { getParameterByName } from '../Other/utils';
const theme = createMuiTheme({
	palette: {
		primary: blue,
		secondary: yellow,
		error: red,
		contrastThreshold: 3,
		tonalOffset: 0.2
	}
});

const img = {
	position: 'absolute',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	textAlign: 'center',
	top: '50%',
	left: '50%',
	transform: `translate(-50%, -50%)`,
	width: 100,
	height: 75
};

/**
 * Global enum variable for app environments.
 */
const APP_ENVIRONMENTS = {
	DEV: "development",
	STAGING: "staging",
	PROD: "production"
};

// Authenticated routes allow routes to forward users to the login page if they are not authenticated.
const AuthenticatedRoute = ({ component: Component, ...rest }) => (
	<Route {...rest} render={(props) => (
		sessionStorage.getItem("jwt_token")
			? <Component {...props} />
			: <Redirect to='/login' />
	)} />
);

class App extends Component {
	state = {
		authenticated: false,
		tfaAuthenticated: false,
		drawerOpen: false,
		authAlertVisible: false,
		processing: true,
		userId: 0,
		loginUser: null
	};

	toggleDrawer = () => {
		this.setState({ drawerOpen: (this.state.authenticated ? !this.state.drawerOpen : false) });
	};
	
	isAdminUser = (user) => {
		return (user && Array.isArray(user.roles) && user.roles.find((data) => { return data.code && data.code.toLowerCase() === "admin"; }));
	};

	isNotAuthenticated = () => {
		sessionStorage.clear();
		this.setState({ authenticated: false, processing: false, tfaAuthenticated: false });
	};

	/**
	 * Login submit handler. Passed to component.
	 * @param string email 
	 * @param string password 
	 */
	authenticateTheUser = async (email, password) => {
		if (!email || !password) {
			return false;
		}
		
		let responseData = await post('/authenticate/', { email, password },
			(result) => {
				sessionStorage.setItem("login_result", JSON.stringify(result));
				let userData = result.user;
				let isAdminUser = this.isAdminUser(userData);
				if (result.status === "success" && userData) {

					if (typeof isAdminUser !== "undefined") {

						// Update state and set user id for use with tfa.
						sessionStorage.setItem("user_id", userData.id);
						sessionStorage.setItem("login_user", JSON.stringify(userData));
						this.setState({ 
							authenticated: true,
							tfaAuthenticated: false,
							processing: false
						});

						return true;
					} else {
						// Not an admin user, @TODO: Add specific error message about permissions
						this.isNotAuthenticated();
						return false;
					}

				} else {
					this.isNotAuthenticated();
					return false;
				}
				
			},
			this.isNotAuthenticated
		);

		return this.state.authenticated;
	};

	checkLoginStatus = () => {
		let token = sessionStorage.getItem("jwt_token");
		let user_id = sessionStorage.getItem("user_id");
		let authenticated = user_id ? true : false;
		this.setState({ 
			tfaAuthenticated: token ? true : false,
			authenticated: authenticated, 
			processing: false, 
			userId: user_id 
		});
		
	};
	hideAuthAlert = () => this.setState({ authAlertVisible: false });

	componentDidMount() {
		// Disable console logs for pruduction. 
		if (process.env.NODE_ENV === APP_ENVIRONMENTS.PROD) {
			console.log = () => {};
		}

		let paramToken = getParameterByName('token');
		if (paramToken) {
			authorizeWithToken(paramToken).then((json) => {
				// Redirect user so that token now longer shows in url.
				window.location = "/"
			});
		} else {
			this.checkLoginStatus();
		}

		
	}

	render() {
		let { authenticated, authAlertVisible, drawerOpen, processing, userId } = this.state;
		return (
			<MuiThemeProvider theme={theme}>
				<Router>
					<Fragment>
						<Header toggleDrawer={this.toggleDrawer}/>
						<Dialog open={authAlertVisible}>
							<DialogTitle>Auth Error</DialogTitle>
							<DialogContent>
								<DialogContentText>Could not authenticate you as an admin.</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.hideAuthAlert} color="primary">OK</Button>
							</DialogActions>
						</Dialog>
						{
							processing ? (
								<img alt="processing request" style={img} src={loaderImg}/>
							) : (
								<Fragment>
									<SideBar isNotAuthenticated={this.isNotAuthenticated} open={drawerOpen} toggleDrawer={this.toggleDrawer}/>
									<Switch>
										<AuthenticatedRoute exact path="/" component={() => <ClientsPage isAdminSite={true} isNotAuthenticated={this.isNotAuthenticated} userId={userId}/>}/>
										<AuthenticatedRoute exact path="/questions" component={() => <QuestionsPage isAdminSite={true} isNotAuthenticated={this.isNotAuthenticated}/>}/>
										<AuthenticatedRoute exact path="/clients" component={() => <ClientsPage isAdminSite={true} isNotAuthenticated={this.isNotAuthenticated} userId={userId}/>}/>
										<AuthenticatedRoute exact path="/clients/:clientId/interviews/new" component={() => <EditInterviewPage isAdminSite={true} isNotAuthenticated={this.isNotAuthenticated}/>}/>
										<AuthenticatedRoute exact path="/clients/:clientId/interviews/templates" component={() => <TemplateInterviewPage isAdminSite={true} isNotAuthenticated={this.isNotAuthenticated}/>}/>
										<AuthenticatedRoute exact path="/clients/:clientId/interviews/:interviewId" component={() => <EditInterviewPage isAdminSite={true} isNotAuthenticated={this.isNotAuthenticated}/>}/>
										<AuthenticatedRoute exact path="/clients/:clientId/interviews" component={() => <InterviewsPage isAdminSite={true} isNotAuthenticated={this.isNotAuthenticated}/>}/>
										<AuthenticatedRoute exact path="/clients/:clientId/users" component={() => <ClientUsersPage isAdminSite={true} isNotAuthenticated={this.isNotAuthenticated}/>}/>
										<AuthenticatedRoute exact path="/users" component={() => <UsersPage isAdminSite={true} isNotAuthenticated={this.isNotAuthenticated}/>}/>
										<AuthenticatedRoute exact path="/password" component={() => <PasswordPage isAdminSite={true} isNotAuthenticated={this.isNotAuthenticated}/>}/>
										<Route exact path="/login" component={() => <LogInForm authenticateHandle={this.authenticateTheUser} isAdminSite={true} />}/>
										<Route exact path="/forgot" component={() => <ForgotPassword isAdminSite={true} resetPassword={resetPassword}/>}/>
										<Route exact path="/validate" component={() => <TFAValidation isAdminSite={true} />}/>
										<Route exact path="/tfa_select" component={() => <TFASelection isAdmiSite={true} />}/>
										<Route exact path="/password/:jwt" component={() => <PasswordPage isAdminSite={true} isNotAuthenticated={this.isNotAuthenticated}/>}/>
									</Switch>
								</Fragment>
							)
						}
					</Fragment>
				</Router>
			</MuiThemeProvider>
		);
	}
}

export default App;
