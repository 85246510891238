
function getCookie(name) {
	let value = "; " + document.cookie;
	let parts = value.split(", " + name + "=");
	if (parts.length === 2) {
		return parts.pop().split(",").shift();
	}
}

function getParameterByName(name) {
	let windowUrl = window.location.href;
	name = name.replace(/[[\]]/g, '\\$&');
	let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
		results = regex.exec(windowUrl);
	if (!results) {
		return null;
	}
	if (!results[2]) {
		return '';
	}
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function paramExists(name) {
	let windowUrl = window.location.href;
	name = name.replace(/[[\]]/g, '\\$&');
	let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
		results = regex.exec(windowUrl);
	return !!results;
}

function to(promise) {
	return promise.then(data => [null, data]).catch(err => [err]);
}

function too(promiseArr) {
	return Promise.all(promiseArr).then(sources => [null, sources]).catch(err => [err]);
}

function randInt(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

function sortArr(arr, sortProps) {
	const newArr = [...arr];
	newArr.sort((a, b) => {
		for (let i = 0; i < sortProps.length; i++) {
			let sortProp = sortProps[i];
			if (typeof a[sortProp] === "string" && typeof b[sortProp] === "string") {
				if (a[sortProp].toLowerCase() < b[sortProp].toLowerCase()) {
					return -1;
				}
				if (a[sortProp].toLowerCase() > b[sortProp].toLowerCase()) {
					return 1;
				}
			}
			if (a[sortProp] < b[sortProp]) {
				return -1;
			}
			if (a[sortProp] > b[sortProp]) {
				return 1;
			}
		}
		return 0;
	});
	return newArr;
}

function formatTime (seconds) {
	let minutes = Math.floor(seconds / 60);
	seconds = seconds - minutes * 60;
	minutes = minutes < 10 ? `0${minutes}` : minutes;
	seconds = seconds < 10 ? `0${seconds}` : seconds;
	return `${minutes}:${seconds}`
}

export {
	getCookie,
	getParameterByName,
	paramExists,
	randInt,
	to,
	too,
	sortArr,
	formatTime
};
