
import React from 'react';
import FontAwesome from 'react-fontawesome';
import { IconButton } from '@material-ui/core';

export default class Music extends React.Component {
	constructor(props) {
		super(props);
		this.state = { play: true };
		this.audio = new Audio(this.props.url);
		this.togglePlay = this.togglePlay;
	}

	togglePlay = () => {
		this.setState({ play: !this.state.play });
		this.state.play ? this.audio.play() : this.audio.pause();
	};

	render() {
		return (
			<IconButton onClick={this.togglePlay}>
				{this.state.play ? <FontAwesome name='play'/> : <FontAwesome name='pause'/>}
			</IconButton>
		);
	}
}
