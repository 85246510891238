
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography, Slide, Button, FormControl, Input, FormHelperText, CircularProgress, Dialog, DialogContent, DialogContentText, DialogActions, TextField } from '@material-ui/core';
import { LoadingButton } from '../Components';
import { get, post, put } from '../Other/api';

const passwordRegExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{5,}$/;
const styles = theme => ({
	backDrop: {
		backgroundColor: 'transparent'
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	},
	dialog: {
		backgroundColor: 'transparent !important',
		height: '100%',
		left: 0,
		position: 'fixed',
		top: 0,
		width: '100%'
	},
	dialogContent: {
		backgroundColor: '#99C220',
		borderRadius: '5px',
		display: 'table-cell !important',
		height: '50px',
		left: '50%',
		padding: '20px',
		position: 'fixed',
		top: 'calc(100% - 120px)',
		transform: 'translate(-50%,-50%)',
		verticalAlign: 'middle !important',
		width: '90%'
	},
	dialogLink: {
		color: '#FFFFFF',
		float: 'right',
		height: '20px',
		marginTop: '14px',
		width: '150px !important'
	},
	dialogTitle: {
		color: '#FFFFFF',
		float: 'left',
		height: '20px',
		marginTop: '14px',
		textAlign: 'left',
		width: '50% !important'
	},
	divider: {
		height: theme.spacing.unit * 2
	},
	errorText: {
		marginBottom: theme.spacing.unit
	},
	expired: {
		textAlign: 'center'	
	},
	paper: {
		padding: theme.spacing.unit * 3,
		width: 600,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	processed: {
		backgroundColor: '#99C220',
		color: '#FFFFFF',
		cursor: 'pointer',
		font: 'Regular 12px/14px Work Sans',
		textAlign: 'right',
		textDecoration: 'underline',
		'&:hover': {
			color: '#B5D7FF'
		}
	},
	return: {
		color: '#BDBDBD',
		cursor: 'pointer',
		font: 'Regular 12px/14px Work Sans',
		marginTop: theme.spacing.unit * 3,
		textAlign: 'center',
		textDecoration: 'underline',
		'&:hover': {
			color: '#2D64AA'
		}
	},
	root: {
		height: 'calc(100% - 64px)',
		boxSizing: 'border-box',
		display: 'flex',
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center'
	},
	textField: {
		marginBottom: theme.spacing.unit
	},
	wrapper: {
		margin: theme.spacing.unit,
		position: 'relative'
	}
});

class PasswordPage extends Component {
	state = {
		updating: false,
		password: '',
		passwordConfirmation: '',
		passwordError: false,
		passwordConfirmationError: '',
		confirmUpdateAlertVisible: false,
		updatedAlertVisible: false,
		clientId: 0,
		resetDone: false,
		resetTitle: '',
		useJWT: false,
		validJWT: false
	};

	componentDidMount() {
		let { jwt } = this.props.match.params;
		if (jwt) {
			post('/users/password/valid', { token: jwt },
				({ valid }) => { return this.setState({ useJWT: true, validJWT: valid }); },
				this.props.isNotAuthenticated
			);
		}
		else {
			get('/sessions', null,
				({ user: { client_id } }) => { return this.setState({ clientId: client_id || 0 }); },
				this.props.isNotAuthenticated
			);
		}
	}

	submitForm = (event) => {
		event.preventDefault();
		this.setState({ passwordError: false, passwordConfirmationError: '' });
		let { password, passwordConfirmation } = this.state;
		
		if (password.length < 5 || !passwordRegExp.test(password)) {
			this.setState({ passwordError: true });
		}
		else if (password !== passwordConfirmation) {
			this.setState({ passwordConfirmationError: 'Passwords do not match.' });
		}
		else {
			this.setState({ confirmUpdateAlertVisible: true });
		}
	};
	
	updatePassword = async () => {
		if (!this.state.updating) {
			this.setState({ confirmUpdateAlertVisible: false, updating: true });
			let { password } = this.state;
			let { jwt } = this.props.match.params;
			
			if (jwt) {
				await post('/users/password/token', { password, token: jwt }, 
					() => { this.setState({ resetDone: true, updating: false, updatedAlertVisible: false, resetTitle: 'Password reset!' }); }, 
					() => { this.setState({ resetDone: true, updating: false, updatedAlertVisible: false, resetTitle: 'An error occurred while attempting to reset password. Please try again later.' }); });
			}
			else {
				await put('/users/password', { password },
					() => {
						this.setState({
							password: '',
							passwordConfirmation: '',
							passwordError: false,
							passwordConfirmationError: '',
							updating: false,
							updatedAlertVisible: true
						});
					},
					this.props.isNotAuthenticated
				);
			}
		}
	};

	enterPassword = ({ target: { value } }) => this.setState({ password: value });

	enterPasswordConfirmation = ({ target: { value } }) => this.setState({ passwordConfirmation: value });

	showConfirmUpdateAlert = () => this.setState({ confirmUpdateAlertVisible: true });

	hideConfirmUpdateAlert = () => this.setState({ confirmUpdateAlertVisible: false });

	showUpdatedAlert = () => this.setState({ updatedAlertVisible: true });

	hideUpdatedAlert = () => {
		this.props.history.push('/');	// redirects to login screen
	};
	
	backdropClick = () => {
		this.setState({ resetTitle: '', resetDone: false });
		this.hideUpdatedAlert();
	};

	render() {
		let { classes } = this.props;
		let {
			updating,
			passwordError,
			passwordConfirmationError,
			password,
			passwordConfirmation,
			confirmUpdateAlertVisible,
			updatedAlertVisible,
			clientId,
			resetDone,
			resetTitle,
			useJWT,
			validJWT
		} = this.state;
		
		return (
			<Slide direction="left" in={true}>
				<div className={classes.root}>
					<Paper className={classes.paper}>
						{
							useJWT && !validJWT ? (
									<Typography className={classes.expired} variant="title" gutterBottom>
										This link is invalid, expired, or has already been used.<br/><br/>
										Please acquire another link to reset your password.
									</Typography>
							) : (
								<div>
									<Typography variant="display1" gutterBottom>{useJWT ? 'Reset' : 'Change'} Password</Typography>
									<div className={classes.divider}/>
									<form onSubmit={this.submitForm}>
										<FormControl fullWidth>
											<TextField
												placeholder="New Password"
												type="password"
												fullWidth
												error={passwordError}
												onChange={this.enterPassword}
												className={classes.textField}
												helperText={'Password must be at least 5 characters long and must contain at least 1 letter, 1 number, and 1 special character: @ $ ! % * # ? &'}
												value={password}
											/>
										</FormControl>
										<FormControl fullWidth>
											<Input
												placeholder="Confirm New Password"
												type="password"
												fullWidth
												error={false}
												onChange={this.enterPasswordConfirmation}
												value={passwordConfirmation}
											/>
											<FormHelperText error className={classes.errorText}>{passwordConfirmationError}</FormHelperText>
										</FormControl>
										<div className={classes.wrapper}>
											{
												updating ? <LoadingButton height={20}/> :
												<Button
													type="submit"
													variant="contained"
													color="primary"
													fullWidth
													onClick={this.submitForm}
													disabled={updating}
												>
													{useJWT ? 'Reset' : 'Update'} Password
												</Button>
											}
										</div>
									</form>
								</div>
							)
						}
						{
							useJWT &&
							<Link to="/">
								<Typography className={classes.return} gutterBottom variant="subheading">Return to Log-In</Typography>
							</Link>
						}
					</Paper>
					<Dialog
						open={confirmUpdateAlertVisible}
						onClose={this.hideConfirmUpdateAlert}
						aria-labelledby="confirm-update-alert-title"
						aria-describedby="confirm-update-alert-description"
					>
						<DialogContent>
							<DialogContentText>As your password will be changing, we will need you to log back in with your new password. Click confirm to proceed.</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.hideConfirmUpdateAlert}>Cancel</Button>
							<Button onClick={this.updatePassword} color="primary">Confirm</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						open={updatedAlertVisible}
						onClose={this.hideUpdatedAlert}
						aria-labelledby="updated-alert-title"
						aria-describedby="updated-alert-description"
					>
						<DialogContent>
							<DialogContentText>
								Your password has been updated.
								{!!clientId && (' Please make sure to update your setup in HR Fuse to use your new password or the integration with your demo site will no longer work.')}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.hideUpdatedAlert}>OK</Button>
						</DialogActions>
					</Dialog>
					{
						resetDone &&
						<div className={classes.dialog} onClick={this.backdropClick}>
							<div className={classes.dialogContent}>
								<Typography className={classes.dialogTitle} variant="title">{resetTitle}</Typography>
							
								<Link className={classes.dialogLink} to="/">
									<Typography className={classes.processed} variant="subheading">Return to Log-In</Typography>
								</Link>
							</div>
						</div>
					}
				</div>
			</Slide>
		);
	}
}

export default withRouter(withStyles(styles)(PasswordPage));
