
import { to } from './utils';

let baseURN, baseUrl;
if (window.location.hostname.includes('localhost')) {
	baseURN = 'localhost:1338';
	baseUrl = 'http://localhost:1338';
}
else if (window.location.hostname.includes('staging')) {
	baseURN = 'staging-api.dialogg.io';
	baseUrl = 'https://staging-api.dialogg.io';
}
else {
	baseURN = 'api.dialogg.io';
	baseUrl = 'https://api.dialogg.io';
}

const reqHeaders = {
	credentials: 'include',
	mode: 'cors',
	method: 'GET',
	headers: {
		'pragma': 'no-cache',
		'cache-control': 'max-age=0, must-revalidate, no-cache, no-store, private',
		'Access-Control-Allow-Credentials': baseURN
	}
};

function setHeaders(obj) {
	if (obj.headers) {
		obj.headers = Object.assign({}, reqHeaders.headers, obj.headers);
	}
	return Object.assign({}, reqHeaders, obj);
}

async function authorizeWithToken(token) {
	
	const headers = setHeaders({
		method: 'POST',
		headers: { token }
	});
	const [err, json] = await to(
		fetch(`${baseUrl}/sessions/tokens`, headers)
			.then((response) => { return response.json(); })
			.then((json) => { return json; })
	);
		console.log(json);
	if (json.hasOwnProperty("info")) {
		sessionStorage.setItem("jwt_token", token);
		sessionStorage.setItem("user_id", json.info.id);
	}
	return err || json;
}

async function authorizeWithCookies() {
	const [err, json] = await to(
		fetch(`${baseUrl}/sessions`, { ...reqHeaders, 'Content-Type': 'application/json' })
			.then((response) => { return response.json(); })
			.then((json) => { return json; })
	);
	return err || json;
}

async function authenticateUser(email, password) {
	if (!email || !password) {
		return false;
	}
	const headers = setHeaders({
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': 'alchemy.ngrok.io',
		},
		method: 'POST',
		body: JSON.stringify({ email, password }),
	});

	const [err, json] = await to(
		fetch(`${baseUrl}/sessions`, headers)
			.then((response) => { return response.json(); })
			.then((json) => { return json; })
	);
	return err || json;
}

async function makeRequest(path, method, additionalHeaders, body = {}, onSuccess, onFailed) {
	
	// Check session for jwt token.
	let token = sessionStorage.getItem("jwt_token");
	if (token) {
		additionalHeaders['Authorization'] = "Bearer " + token;
	}

	let headers = setHeaders({
		headers: {
			'Accept': 'application/json',
			'Access-Control-Allow-Credentials': 'alchemy.ngrok.io',
			'Pragma': 'no-cache',
			'Cache-Control': 'max-age=0, must-revalidate, no-cache, no-store, private',
			...additionalHeaders,
		},
		method,
		body
	});

	if (method === 'GET') {
		delete headers['body'];
	} // necessary for Edge v16
	let [err, json] = await to(
		fetch(`${baseUrl}${path}`, headers)
			.then((data) => { return data.json(); })
			.then((json) => { return json; })
	);
	if (err) {
		if (onFailed) {
			return onFailed(err);
		}
		return err;
	}

	if (onSuccess && json.status === "success") {
		onSuccess(json);
	}
	if (onFailed && (json.status === "error" || json.status === "failed")) {
		onFailed(json);
	}
	return json;
}

function get(path, obj, onS, onF) {
	let url = (obj ? `${path}?${Object.keys(obj).map(key => `${key}=${encodeURIComponent(obj[key])}`).join("&")}` : path);
	return makeRequest(url, "GET", { 'Content-Type': 'application/json' }, null, onS, onF);
}

async function post(path, obj, onS, onF) {
	return await makeRequest(path, "POST", { 'Content-Type': 'application/json' }, JSON.stringify(obj), onS, onF);
}

async function put(path, obj, onS, onF) {
	return await makeRequest(path, "PUT", { 'Content-Type': 'application/json' }, JSON.stringify(obj), onS, onF);
}

async function remove(path, onS, onF) {
	return await makeRequest(path, "DELETE", { 'Content-Type': 'application/json' }, null, onS, onF);
}

const del = remove;

async function postFormData(path, obj, onS, onF) {
	const body = new FormData();
	Object.keys(obj).forEach((key) => body.append(key, obj[key]));
	return makeRequest(path, "POST", {}, body, onS, onF);
}

async function resetPassword(email = null) {
	let success = false;
	await post('/users/password/reset', { email: email },
		(res) => { success = !!res.token; },
		//(err) => { this.setState({ processing: false }); } // this is an error this is not in a component.
	);
	return success;
}

export {
	authorizeWithCookies,
	authorizeWithToken,
	authenticateUser,
	setHeaders,
	baseUrl,
	reqHeaders,
	get,
	post,
	postFormData,
	resetPassword,
	put,
	remove,
	del
};
