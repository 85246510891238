
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Divider, IconButton, Button, Tooltip, Grow, Menu, MenuItem, Typography } from '@material-ui/core';
import FontAwesome from 'react-fontawesome';
import { DemoInterviewDialog, InterviewInviteDialog } from '../Dialogs';

function styles(theme) {
	return {
		container: {
			padding: theme.spacing.unit * 2,
			paddingBottom: 0,
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
			justifyContent: 'space-between'
		},
		buttonsContainer: {
			display: 'flex',
			justifyContent: 'space-between'
		},
		button: {
			margin: theme.spacing.unit,
			marginTop: 5
		},
		txtButton: {
			margin: theme.spacing.unit,
			marginLeft: 0
		},
		activeCardText: {},
		inactiveCardText: {
			color: '#c5c4bc'
		}
	};
}

class InterviewCard extends Component {
	state = {
		demoInterviewDialogVisible: false,
		inviteDialogVisible: false,
		menuAnchorEl: null
	};

	openMenu = ({ currentTarget }) => { this.setState({ menuAnchorEl: currentTarget }); };
	closeMenu = () => { this.setState({ menuAnchorEl: null }); };
	showDemoInterviewDialog = () => { this.setState({ demoInterviewDialogVisible: true }); };
	hideDemoInterviewDialog = () => { this.setState({ demoInterviewDialogVisible: false }); };
	showInviteDialog = () => { this.setState({ inviteDialogVisible: true }); };
	hideInviteDialog = () => { this.setState({ inviteDialogVisible: false }); };

	render() {
		let { classes, index, handleArchive, interview, editInterview, showCopiedLinkAlert, showInviteSentAlert } = this.props;
		let { menuAnchorEl, demoInterviewDialogVisible, inviteDialogVisible } = this.state;
		let unrecordedQuestionsExist = interview.questions.filter(({ recording_url }) => { return !recording_url; }).length;
		let cardTextClass = (unrecordedQuestionsExist ? classes.inactiveCardText : classes.activeCardText);
		
		return (
			<Grow in>
				<Paper className={classes.container}>
					<Typography variant="subheading" gutterBottom align="left" className={cardTextClass}>
						{interview.title}
					</Typography>
					<Typography variant="caption" gutterBottom align="left" className={cardTextClass}>
						{interview.questions.length} Questions
					</Typography>
					<Typography variant="caption" gutterBottom align="left" className={cardTextClass}>{unrecordedQuestionsExist ? '(Questions being processed)' : ''}&nbsp;</Typography>
					<Divider/>
					<div className={classes.buttonsContainer}>
						<Tooltip id="tooltip-invite" title="Edit the interview">
							<Button color="primary" size="small" className={classes.txtButton} onClick={editInterview}>Edit</Button>
						</Tooltip>
						<Tooltip id="tooltip-invite" title="Choose an interview action">
							<IconButton aria-label="Menu" size="small" onClick={this.openMenu} className={classes.button}>
								<FontAwesome name="ellipsis-v"/>
							</IconButton>
						</Tooltip>
						<Menu anchorEl={menuAnchorEl} open={!!menuAnchorEl} onClose={this.closeMenu}>
							<MenuItem onClick={() => this.showInviteDialog()}>Invite</MenuItem>
							<MenuItem onClick={this.showDemoInterviewDialog} disabled={!unrecordedQuestionsExist}>Demo Interview</MenuItem>
							<MenuItem onClick={() => handleArchive(index)}>Archive</MenuItem>
						</Menu>
						{
							inviteDialogVisible && 
							<InterviewInviteDialog
								clientId={interview.client_id}
								interviewId={interview.id}
								token={interview.token}
								hide={this.hideInviteDialog}
								showCopiedLinkAlert={showCopiedLinkAlert}
								showInviteSentAlert={showInviteSentAlert}
							/>
						}
						{
							demoInterviewDialogVisible && 
							<DemoInterviewDialog interviewId={interview.id} hide={this.hideDemoInterviewDialog}/>
						}
					</div>
				</Paper>
			</Grow>
		);
	}
}

export default withStyles(styles)(InterviewCard);
