
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Button, Typography, Grid, Slide, Modal, Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { to } from '../Other/utils';
import { InterviewCard, ArchivedInterviewCard } from '../Components';
import { get, del, put } from '../Other/api';

const styles = theme => ({
	root: {
		boxSizing: 'border-box',
		width: '100%',
		maxWidth: 1200,
		margin: 'auto',
		padding: theme.spacing.unit * 3,
		flexGrow: 1
	},
	interviewHead: {
		width: '100%',
		display: 'inline-block',
		marginBottom: 20,
//		justifyContent: 'space-between'
	},
	flexBottom: {
		alignSelf: 'flex-end',
		display: 'inline-block',
		float: 'left'
	},
	button: {
		float: 'right',
		margin: theme.spacing.unit
	},
	paper: {
		padding: theme.spacing.unit * 2,
		textAlign: 'center',
		color: theme.palette.text.secondary
	},
	gridItem: {
		display: 'flex',
//		flexDirection: 'row',
//		flexWrap: 'wrap'
	},
	modal: {
		position: 'absolute',
		width: theme.spacing.unit * 50,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing.unit * 4,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)'
	},
	getArchivedInterviewBtn: {
		marginTop: theme.spacing.unit * 5,
		marginBottom: theme.spacing.unit * 2
	},
	archivedInterviewsHeader: {
		marginTop: theme.spacing.unit * 5,
		marginBottom: theme.spacing.unit * 2
	}
});

const ClosableSnackbar = ({ hide, visible, message }) => (
	<Snackbar
		anchorOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		onClose={hide}
		open={visible}
		message={message}
		autoHideDuration={6000}
		action={[
			<IconButton
				key="close"
				aria-label="Close"
				color="inherit"
				onClick={hide}
			>
				<CloseIcon/>
			</IconButton>
		]}
	/>
);

class InterviewsPage extends Component {
	state = {
		archivedInterviews: [],
		archivedInterviewsBtnVisible: false,
		archivedInterviewsMounting: false,
		archivedInterviewsVisible: false,
		archiveModalOpen: false,
		clientId: 0,
		copiedLinkAlertVisible: false,
		interviewIndex: null,
		interviews: [],
		interviewsVisible: true,
		inviteSentAlertVisible: false,
		showTemplateButton: false,
		status: ''
	};

	handleArchive = (interviewIndex) => {
		this.setState({
			interviewIndex: interviewIndex,
			archiveModalOpen: true,
		});
	};

	setClientId = async () => {
		let { clientId } = this.props.match.params;
		if (clientId) {
			return this.setState({ clientId });
		}

		let [err, session] = await to(get('/sessions'));
		if (err || session.status !== 'success' || !session.user || !session.user.client_id) {
			return console.error(err, session);
		}
		else {
			this.setState({ clientId: session.user.client_id });
		}
	};

	confirmArchive = async () => {
		let { interviewIndex, interviews, clientId } = this.state;
		
		if (!clientId) {
			return console.error('Client ID not set');
		}
		let interview = interviews[interviewIndex];

		del(`/clients/${clientId}/interviews/${interview.id}`).then(json => {
			if (json.status === 'success') {
				this.setState(({ interviews, archivedInterviewsVisible, archivedInterviews }) => ({
					interviews: interviews.filter((interview, index) => { return index !== interviewIndex; }),
					archivedInterviews: archivedInterviewsVisible ? [...archivedInterviews, interview] : archivedInterviews,
					archiveModalOpen: false,
					interviewIndex: null
				}));
			}
			else {
				this.props.history.push('/');
			}
		});
	};

	restoreArchivedInterview = async (interviewIndex) => {
		let { archivedInterviews, clientId } = this.state;
		if (!clientId) {
			return console.error('Client ID not set');
		}
		let interview = archivedInterviews[interviewIndex];

		put(`/clients/${clientId}/interviews/${interview.id}`, { archived: false }).then(json => {
			if (json.status === 'success') {
				this.setState(({
					interviews,
					archivedInterviews,
				}) => ({
					interviews: [...interviews, interview],
					archivedInterviews: archivedInterviews.filter((interview, index) => index !== interviewIndex),
				}));
			}
			else {
				this.props.history.push('/');
			}
		});
	};

	handleModalClose = () => {
		this.setState({
			interviewIndex: null,
			archiveModalOpen: false,
		});
	};

	mountInterviews = (interviews) => {
		if (!interviews.length) {
			this.setState({ archivedInterviewsBtnVisible: true });
			return false;
		}
		let [interview] = interviews;
		this.setState({ interviews: [interview] });
		if (interviews.length === 1) {
			this.setState({ archivedInterviewsBtnVisible: true });
			return;
		}
		let i = 1;
		let interval = setInterval(() => {
			let interview = interviews[i];
			this.setState(({ interviews }) => ({ interviews: interviews.concat(interview) }));
			i++;
			if (i === interviews.length) {
				clearInterval(interval);
				setTimeout(() => this.setState({ archivedInterviewsBtnVisible: true }), 200);
			}
		}, 200);
	};

	mountArchivedInterviews = (archivedInterviews) => {
		if (!archivedInterviews.length) {
			return;
		}
		let [archivedInterview] = archivedInterviews;
		this.setState({
			archivedInterviews: [archivedInterview],
			archivedInterviewsMounting: true,
		});
		if (archivedInterviews.length === 1) {
			return;
		}
		let i = 1;
		let interval = setInterval(() => {
			let archivedInterview = archivedInterviews[i];
			this.setState(({ archivedInterviews }) => ({
				archivedInterviews: archivedInterviews.concat(archivedInterview)
			}));
			i++;
			if (i === archivedInterviews.length) {
				clearInterval(interval);
				this.setState({ archivedInterviewsMounting: false });
			}
		}, 200);
	};

	getInterviews = async () => {
		let { clientId } = this.state;
		if (!clientId) {
			return console.error('Client ID not set');
		}
		let [interviewErr, interview] = await to(get(`/clients/${clientId}/interviews`));
		if (interviewErr) {
			console.log(interviewErr);
		}
		if (interview.message && interview.message === 'Account is inactive. Talk to support') {
			this.props.history.push('/account');
		}
		else {
			(interview.status === 'success' ? this.mountInterviews(interview.interviews) : this.props.isNotAuthenticated());
		}
	};

	getArchivedInterviews = async () => {
		let { clientId } = this.state;
		if (!clientId) {
			return console.error('Client ID not set');
		}
		let [interviewErr, interview] = await to(get(`/clients/${clientId}/interviews?archived=true`));
		if (interviewErr) {
			console.log(interviewErr);
		}
		if (interview.message && interview.message === 'Account is inactive. Talk to support') {
			this.props.history.push('/account');
		}
		else {
			interview.status === 'success' ? this.mountArchivedInterviews(interview.interviews) : this.props.isNotAuthenticated();
		}
	};

	getAccountDetails = async () => {
		let { clientId } = this.state;
		if (!clientId) {
			return console.error('Client ID not set');
		}
		
		let [clientErr, clientInfo] = await to(get(`/clients/${clientId}`));
		if (clientErr || !clientInfo || !clientInfo.client || clientInfo.client.is_active !== 1) {
			this.props.history.push('/account');
		}
		
		this.setState({ showTemplateButton: (clientInfo && clientInfo.client && clientInfo.client.allow_interview_templates) });
	};

	componentDidMount() {
		this.setClientId().then(() => {
			this.getAccountDetails();
			this.getInterviews()
		});
	}

	showArchivedInterviews = () => {
		this.setState(
			{
				archivedInterviewsVisible: true,
				archivedInterviewsBtnVisible: false,
			},
			() => this.getArchivedInterviews(),
		);
	};

	showCopiedLinkAlert = () => this.setState({ copiedLinkAlertVisible: true });

	hideCopiedLinkAlert = () => this.setState({ copiedLinkAlertVisible: false });

	showInviteSentAlert = () => this.setState({ inviteSentAlertVisible: true });

	hideInviteSentAlert = () => this.setState({ inviteSentAlertVisible: false });

	render() {
		let {
			classes,
			history,
			match
		} = this.props;
		let {
			archiveModalOpen,
			archivedInterviewsBtnVisible,
			archivedInterviewsVisible,
			archivedInterviews,
			copiedLinkAlertVisible,
			interviews,
			interviewsVisible,
			inviteSentAlertVisible,
			showTemplateButton
		} = this.state;
		let { clientId } = match.params;
		let interviewBaseURL = clientId ? `/clients/${clientId}/interviews` : '/interviews';
		
		return (
			<Fragment>
				<Slide
					direction="right"
					in={interviewsVisible}
					mountOnEnter
					unmountOnExit
				>
					<div className={classes.root}>
						<div className={classes.interviewHead}>
							<Typography variant="headline" align="center" gutterBottom className={classes.flexBottom}>Interviews</Typography>
							<Button variant="contained" color="primary" className={classes.button} onClick={() => this.props.history.push(`${interviewBaseURL}/new`)}>Add Custom Interview</Button>
							{
								showTemplateButton && <Button variant="contained" color="primary" className={classes.button} onClick={() => this.props.history.push(`${interviewBaseURL}/templates`)}>Add Template Interview</Button>
							}
						</div>
						<Grid container spacing={24}>
							{
								interviews.map((interview, index) => { return (
									<Grid key={interview.id} className={classes.gridItem} xs={6} sm={4} md={3} item>
										<InterviewCard
											index={index}
											handleArchive={this.handleArchive}
											showCopiedLinkAlert={this.showCopiedLinkAlert}
											showInviteSentAlert={this.showInviteSentAlert}
											interview={{ ...interview }}
											editInterview={() => { history.push(`${interviewBaseURL}/${interview.id}`); }}
										/>
									</Grid>
								)}
							)}
						</Grid>
						<Modal aria-labelledby="confirm-interview-archive" aria-describedby="confirm-interview-archive" open={archiveModalOpen} onClose={this.handleModalClose}>
							<div className={classes.modal}>
								<Typography variant="title" id="modal-title">Are you sure about this?</Typography>
								<Typography variant="caption" gutterBottom>Click outside the box to cancel</Typography>
								<Button onClick={this.confirmArchive}>Yes Archive This!</Button>
							</div>
						</Modal>
						{
							archivedInterviewsBtnVisible &&
							<Button onClick={this.showArchivedInterviews} className={classes.getArchivedInterviewBtn} color="primary">View Archived Interviews</Button>
						}
						{
							archivedInterviewsVisible && 
							<Fragment>
								<Typography variant="title" className={classes.archivedInterviewsHeader} gutterBottom>Archived Interviews</Typography>
								<Grid container spacing={24}>
									{archivedInterviews.map((interview, index) => (
										<Grid key={interview.id} className={classes.gridItem} xs={6} sm={4} md={3} item>
											<ArchivedInterviewCard
												index={index}
												restoreArchivedInterview={this.restoreArchivedInterview}
												editInterview={() => history.push(`${interviewBaseURL}/${interview.id}`)}
												{...interview}
											/>
										</Grid>
									))}
								</Grid>
							</Fragment>
						}
					</div>
				</Slide>
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					onClose={this.hideCopiedLinkAlert}
					open={copiedLinkAlertVisible}
					message="Link copied to clipboard"
					autoHideDuration={6000}
					action={[
						<IconButton key="close" aria-label="Close" color="inherit" onClick={this.hideCopiedLinkAlert}>
							<CloseIcon/>
						</IconButton>
					]}
				/>
				<ClosableSnackbar
					visible={copiedLinkAlertVisible}
					hide={this.hideCopiedLinkAlert}
					message="Link copied to clipboard"
				/>
				<ClosableSnackbar
					visible={inviteSentAlertVisible}
					hide={this.hideInviteSentAlert}
					message="Invite sent to candidate"
				/>
			</Fragment>
		);
	}
}

export default withRouter(withStyles(styles)(InterviewsPage));
