
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { LoadingButton } from '../Components';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogContent, Paper, TextField, Typography } from '@material-ui/core';

const styles = theme => ({
	backDrop: {
		backgroundColor: 'transparent'
	},
	button: {
		marginTop: theme.spacing.unit * 3
	},
	container: {
		boxSizing: 'border-box',
		width: '100%',
		maxWidth: 600,
		margin: 'auto',
		marginTop: 50,
		padding: 30,
		flexGrow: 1
	},
	dialog: {
		backgroundColor: 'transparent',
		height: '100%',
		left: 0,
		position: 'fixed',
		top: 0,
		width: '100%'
	},
	dialogContent: {
		backgroundColor: '#99C220',
		borderRadius: '5px',
		display: 'table-cell !important',
		height: '50px',
		left: '50%',
		padding: '20px',
		position: 'fixed',
		top: 'calc(100% - 120px)',
		transform: 'translate(-50%,-50%)',
		verticalAlign: 'middle !important',
		width: '90%'
	},
	dialogLink: {
		color: '#FFFFFF',
		float: 'right',
		height: '20px',
		marginTop: '14px',
		width: '150px !important'
	},
	dialogTitle: {
		color: '#FFFFFF',
		float: 'left',
		height: '20px',
		marginTop: '14px',
		textAlign: 'left',
		width: '50% !important'
	},
	done: {
		textAlign: 'left'	
	},
	errorMsg: {
		color: '#AA0000',
		marginTop: theme.spacing.unit * 3,
		textAlign: 'center'
	},
	headline: {
		color: '#707070',
		font: 'Regular 32px/38px Work Sans'
	},
	processed: {
		backgroundColor: '#99C220',
		color: '#FFFFFF',
		cursor: 'pointer',
		font: 'Regular 12px/14px Work Sans',
		textAlign: 'right',
		textDecoration: 'underline',
		'&:hover': {
			color: '#B5D7FF'
		}
	},
	return: {
		color: '#BDBDBD',
		cursor: 'pointer',
		font: 'Regular 12px/14px Work Sans',
		marginTop: theme.spacing.unit * 3,
		textAlign: 'center',
		textDecoration: 'underline',
		'&:hover': {
			color: '#2D64AA'
		}
	},
	subheading: {
		color: '#707070',
		font: 'font: Regular 16px/19px Work Sans'
	}
});

class ForgotPassword extends Component {
	state = {
		email: null,
		error: null,
		processing: false,
		success: false
	};
	
	checkEmail = async () => {
		let email = this.state.email;
		if (!this.state.processing) {
			if (email && email.length > 3) {
				this.setState({ processing: true });
				let success = await this.props.resetPassword(email);
				this.setState({ email: email, error: (success ? false : 'Email does not exist within our system. Please try again.'), processing: false, success: success });
			}
			else {
				this.setState({ error: 'Email is required to reset password.', processing: false });
			}
		}
	};

	handleTextChange = (name) => {
		return (event) => {
			this.setState({ [name]: event.target.value });
		}
	};
	
	backdropClick = () => {
		this.setState({ success: false });
		this.props.history.push('/');
	};

	render() {
		let { classes } = this.props;
		let { error, processing, success } = this.state;
		
		return (
			<div>
				<Paper className={classes.container}>
					<Typography className={classes.headline} variant="headline" gutterBottom>Reset Password Request</Typography>
					<Typography className={classes.subheading} variant="subheading" gutterBottom>Please enter your email address to request a password reset.</Typography>
					<TextField
						label="Email"
						type="email"
						fullWidth
						onChange={this.handleTextChange('email')}
						onKeyUp={(e) => { return (e.key === 'Enter' ? this.checkEmail() : null); }}
						margin="normal"
					/>
					{error ? <Typography className={classes.errorMsg} gutterBottom variant="subheading">{error}</Typography> : ''}
					{
						processing ? <LoadingButton className={classes.button} height={20}/> :
						<Button
							variant="contained"
							color="primary"
							fullWidth
							className={classes.button}
							onClick={this.checkEmail}
						>
							Request Reset Password
						</Button>
					}
					<Link to="/">
						<Typography className={classes.return} gutterBottom variant="subheading">Return to Log-In</Typography>
					</Link>
				</Paper>
				{
					success && 
					<div className={classes.dialog} onClick={this.backdropClick}>
						<div className={classes.dialogContent}>
							<Typography className={classes.dialogTitle} variant="title">Password reset email sent!</Typography>
						
							<Link className={classes.dialogLink} to="/">
								<Typography className={classes.processed} variant="subheading">Return to Log-In</Typography>
							</Link>
						</div>
					</div>
				}
			</div>
		);
	}
}

export default withRouter(withStyles(styles)(ForgotPassword));
