
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, Chip, Button, DialogContent, Typography, DialogContentText, DialogActions, TextField } from '@material-ui/core';
import FontAwesome from 'react-fontawesome';
import { postFormData } from '../Other/api';
import loaderImg from '../assets/images/dribble-loader.gif';

const styles = theme => ({
	dialog: {
		padding: 30
	},
	recordingArea: {
		marginTop: 10,
		display: 'flex',
		flexDirection: 'row',
		flexGrow: 1
	},
	flex: {
		flex: 1
	},
	uploadIcon: {
		flex: 1,
		color: 'white'
	},
	hiddenInput: {
		opacity: 0,
		height: '45px',
		width: '90%',
		position: 'absolute'
	},
	dropZone: {
		height: '100%',
		display: 'flex',
		backgroundColor: 'rgba(33, 150, 243, .31)',
		borderRadius: 30,
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		'&:hover': {
			opacity: .5
		}
	},
	labelInput: {
		width: '75%'
	},
	hackWidth: {
		width: 750
	},
	chip: {
		margin: theme.spacing.unit / 2
	}
});

class QuestionEditDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			processing: false,
			title: '',
			alt_text: '',
			labels: [],
			labelInput: ''
		};
	}

	handleClose = () => {
		let { processing, labelInput, ...updateDetails } = this.state;
		this.props.update(updateDetails);
		this.props.onClose(this.props.selectedValue);
	};

	handleListItemClick = value => {
		this.props.onClose(value);
	};

	handleLabelDelete = data => () => {
		this.setState(state => {
			let labels = [...state.labels];
			let labelToDelete = labels.indexOf(data);
			labels.splice(labelToDelete, 1);
			return { labels };
		});
	};

	labelHandler = event => {
		this.setState({
			labelInput: event.target.value,
		});
	};
	addLabel = event => {
		let keynum;

		if (window.event) { // IE
			keynum = event.keyCode;
		}
		else if (event.which) { // Netscape/Firefox/Opera
			keynum = event.which;
		}

		if (keynum === 13) {
			let newLabels = this.state.labelInput.split(",").map(s => s.trim());
			this.setState({
				labels: this.state.labels.concat(newLabels),
				labelInput: ''
			});
		}
	};
	handleTextChange = name => event => {
		this.setState({
			[name]: event.target.value,
		});
	};

	inputFileClicker = () => {
		document.getElementById('fileInput').click();
	};

	dropHandler = event => {
		console.log(event);
		event.preventDefault();
		event.stopPropagation();
		if (event.dataTransfer.items.length > 1) {
			return false;
		}
		if (event.dataTransfer.items) {
			// Use DataTransferItemList interface to access the file(s)
			for (let i = 0; i < event.dataTransfer.items.length; i++) {
				// If dropped items aren't files, reject them
				if (event.dataTransfer.items[i].kind === 'file') {
					let file = event.dataTransfer.items[i].getAsFile();
					console.log('... file[' + i + '].name = ' + file.name);
					if (file.type === "audio/mp3") {
						this.upload(file);
					}
				}
			}
		}
		else {
			// Use DataTransfer interface to access the file(s)
			for (let i = 0; i < event.dataTransfer.files.length; i++) {
				console.log('... file[' + i + '].name = ' + event.dataTransfer.files[i].name);
			}
		}
	};

	fileHandler = event => {
		console.log(event.target.files);
		if (event.target.files.length > 1) {
			return false;
		}
		for (let i = 0; i < event.target.files.length; i++) {
			let file = event.target.files[i];
			if (file.type === "audio/mp3") {
				this.upload(file);
			}else if (file.type === "audio/mpeg") {
				this.upload(file);
			}else{
				return "File Type was not mp3 or mpeg, file type was "+file.type;
			}
		}

	};

	dragOverHandler = () => {
		console.log('draggedOver');
	};

	upload = file => {
		this.setState({ processing: true })
		let { id } = this.state;
		postFormData(
			`/questions/${id}/audio`,
			{
				file,
				id: this.props.question.id,
			},
			({ recording_url }) => this.setState({
				recording_url,
				processing: false,
			}),
		);
	};

	static getDerivedStateFromProps(nextProps, prevState) {

		if (nextProps.question && nextProps.question.id !== prevState.id) {
			console.log('second', nextProps)
			return { ...nextProps.question };
		}

		return null;
	}

	render() {
		let { classes, onClose, selectedValue, question, update, ...props } = this.props;
		let id = question ? question.id : null;

		return (
			<Dialog onClose={this.handleClose} className={classes.dialog} aria-labelledby="edit-question-details" {...props} onDrop={this.dropHandler}>
				<div onDrop={this.dropHandler} onDragOver={this.dragOverHandler}>
					{this.state.processing
						? <img alt="processing request" style={{
							display: 'flex',
							margin: 'auto',
							width: 100,
							height: 75
						}} src={loaderImg}/>
						: <div style={{ display: 'flex', margin: 'auto', width: 100, height: 75 }}> &nbsp; </div>
					}
					<DialogContentText className={classes.hackWidth}/>
					<DialogContent>
						<Typography variant="headline" gutterBottom>
							Question ID {id}
						</Typography>
						<TextField
							id="editQuestionTitle"
							label="title"
							className={classes.textField}
							value={this.state.title || ''}
							onChange={this.handleTextChange('title')}
							margin="dense"
							fullWidth
						/>
						<TextField
							id="editQuestionText"
							label="Question Text"
							className={classes.textField}
							value={this.state.alt_text || ''}
							onChange={this.handleTextChange('alt_text')}
							margin="dense"
							fullWidth
						/>
						<div className={classes.recordingArea}>
							{(
								this.state.recording_url
									? <audio controls className={classes.flex}>
										<source src={this.state.recording_url}/>
									</audio>
									:
									<div className={classes.flex} onDrop={this.dropHandler} onDragOver={this.dragOverHandler}>
										<div className={classes.dropZone}>
											<input className={classes.hiddenInput} id="fileInput" type="file" onDrop={this.dropHandler} onChange={this.fileHandler}/>​
											<Typography gutterBottom className={classes.uploadIcon}>
												<FontAwesome name="cloud-upload-alt" size="3x"/>
											</Typography>
										</div>
									</div>
							)}

						</div>

						<TextField
							id="addLabel"
							label="Add Labels"
							value={this.state.labelInput || ''}
							onChange={this.labelHandler}
							onKeyDown={this.addLabel}
							helperText="Use commas to seperate multiple tags or hit enter"
							margin="dense"
							fullWidth
						/>
						{this.state.labels.map((label, index) =>
							<Chip
								key={label + index}
								label={label}
								onDelete={this.handleLabelDelete(label)}
								className={classes.chip}
							/>
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} variant="outlined" color="primary">
							Save
						</Button>
					</DialogActions>
				</div>
			</Dialog>
		);
	}
}

QuestionEditDialog.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func,
};

export default withStyles(styles)(QuestionEditDialog);
