
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import Slider from '../Components/Slider';
import slides from '../assets/demo-slides';

const styles = theme => ({
	root: {
		height: '100%',
		margin: theme.spacing.unit * 4
	},
	slideWrapper: {
		display: 'flex',
		flexDirection: 'column',
		height: 'calc(100vh - 160px)',
		padding: theme.spacing.unit * 2,
		margin: 'auto',
		alignItems: 'center'
	},
	paper: {
		display: 'flex',
		height: 'calc(100% - 40px)',
		padding: 0
	},
	img: {
		objectFit: 'contain',
		margin: 'auto',
		maxHeight: '100%',
		maxWidth: '100%'
	}
});

const Slide = props => {
	let { classes, src, title } = props;
	return (
		<div className={classes.slide}>
			<div className={classes.slideWrapper}>
				<Typography variant="display1"> {title} </Typography>
				<Paper className={classes.paper}>
					<img src={src} className={classes.img} alt={title}/>
				</Paper>
			</div>
		</div>
	)
};

const DemoPage = props => {
	let { classes } = props;
	return (
		<div className={classes.root}>
			<Slider>
				{Object.values(slides).map(slide => <Slide classes={classes} title={slide.title} src={slide.src}/>)}
			</Slider>
		</div>
	)
};

export default withStyles(styles)(DemoPage);
