
import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { ListItemIcon, List, ListItem, ListItemText, Drawer } from '@material-ui/core';
import FontAwesome from 'react-fontawesome';
import { get } from '../Other/api';

const styles = {
	list: {
		width: 250
	},
	listItem: {
		boxSizing: 'border-box',
		textAlign: 'center',
		width: 40
	},
	fullList: {
		width: 'auto'
	}
};

const SideBar = ({ classes, isNotAuthenticated, open, toggleDrawer }) => {
	return (
		<Drawer open={open} onClose={toggleDrawer}>
			<div tabIndex={0} role="button" onClick={toggleDrawer} onKeyDown={toggleDrawer} className={classes.list} >
				<List>
					<Link to="/">
						<ListItem button>
							<ListItemIcon className={classes.listItem}>
								<FontAwesome size="2x" name="home"/>
							</ListItemIcon>
							<ListItemText inset primary="Dashboard"/>
						</ListItem>
					</Link>
					<Link to="/questions">
						<ListItem button>
							<ListItemIcon className={classes.listItem}>
								<FontAwesome size="2x" name="file-audio"/>
							</ListItemIcon>
							<ListItemText inset primary="Questions"/>
						</ListItem>
					</Link>
					<Link to="/clients">
						<ListItem button>
							<ListItemIcon className={classes.listItem}>
								<FontAwesome size="2x" name="address-card"/>
							</ListItemIcon>
							<ListItemText inset primary="Clients"/>
						</ListItem>
					</Link>
					<Link to="/users">
						<ListItem button>
							<ListItemIcon className={classes.listItem}>
								<FontAwesome size="2x" name="users"/>
							</ListItemIcon>
							<ListItemText inset primary="Users"/>
						</ListItem>
					</Link>
					<Link to="/password">
						<ListItem button>
							<ListItemIcon className={classes.listItem}>
								<FontAwesome size="2x" name="lock"/>
							</ListItemIcon>
							<ListItemText inset primary="Password"/>
						</ListItem>
					</Link>
					<Link to="/">
						<ListItem button onClick={async () => {
							isNotAuthenticated();
							await get("/logout");
						}}>
							<ListItemIcon className={classes.listItem}>
								<FontAwesome size="2x" name="sign-out-alt"/>
							</ListItemIcon>
							<ListItemText inset primary="Log Out"/>
						</ListItem>
					</Link>
				</List>
			</div>
		</Drawer>
	);
};

export default withStyles(styles)(SideBar);
