
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';
import { Paper, Typography, Collapse, Slide, Button, Modal, Input, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions } from '@material-ui/core';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { EditQuestionCard } from '../Components';
import { get, post, put } from '../Other/api';
import { to, formatTime, sortArr } from '../Other/utils';
import loaderImg from '../assets/images/dribble-loader.gif';

const newRecordingsCreateMsg = 'We are now reviewing and recording your new questions. You will receive an email when it is complete. Processing time is 1 business day';
const newRecordingsUpdateMsg = 'We are now reviewing and recording your new questions. You will receive an email when it is complete. Processing time is 1 business day';
const noNewRecordingsCreateMsg = 'This interview does not require any recordings and is ready to be used right away.';
const noNewRecordingsUpdateMsg = 'The update to this interview does not require any recordings and is ready to be used right away.';
const saveErrorMsg = 'We apologize, but an error has occurred. We are unable to save your changes at this time.';

const styles = (theme) => ({
	pointer: {
		'&:hover': {
			cursor: 'pointer'
		}
	},
	root: {
		boxSizing: 'border-box',
		width: '100%',
		maxWidth: 1200,
		margin: 'auto',
		padding: theme.spacing.unit * 3,
		flexGrow: 1
	},
	header: {
		marginBottom: theme.spacing.unit * 1,
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between'
	},
	headerTitle: {
		display: 'flex',
		alignItems: 'center'
	},
	typog: {
		display: 'flex',
		alignItems: 'center',
		'&:hover': {
			color: theme.palette.primary.main,
			cursor: 'pointer'
		}
	},
	interviewTitle: {
		fontSize: 36,
		marginBottom: theme.spacing.unit * 3
	},
	addQuestionBtn: {
		margin: theme.spacing.unit
	},
	padding: {
		padding: theme.spacing.unit * 2
	},
	paper: {
		zIndex: -5,
		paddingBottom: 5,
		color: theme.palette.text.secondary,
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	modal: {
		position: 'absolute',
		width: theme.spacing.unit * 50,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing.unit * 4,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)'
	},
	loaderImg: {
		display: 'flex',
		margin: 'auto',
		width: 100,
		height: 75
	},
	saveInterviewBtn: {
		float: 'right',
		marginTop: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit * 2
	}
});

const QuestionsListItem = SortableElement((props, index) => {
	const { collapseCheck, dragging, handleMouseEnter, activeQuestion, order } = props;
	const isActive = (order === activeQuestion);
	const isDragging = (dragging && activeQuestion);
	
	return (
		<div onMouseDown={handleMouseEnter(order)}>
			<Collapse in={collapseCheck[order]}>
				<EditQuestionCard
					{...props}
					isActive={isActive}
					dragging={isDragging}
				/>
			</Collapse>
		</div>
	);
});

const QuestionsList = SortableContainer(({ items, ...props }) => {
	return (
		<div>
		{
			items.map((item, index) => {
				return (
					<QuestionsListItem
						key={index}
						index={index}
						order={index}
						{...item}
						{...props}
					/>
				)
			})
		}
		</div>
	)
});

const templateQuestion = { title: '', time_limit: 0, questionType: 'not selected' };
const LoaderImg = ({ classes }) => (
	<img
		alt="processing request"
		className={classes.loaderImg}
		src={loaderImg}
	/>
);

class EditInterviewPage extends Component {
	state = {
		activeQuestion: 0,
		clientId: 0,
		collapseCheck: [],
		confirmModalVisible: false,
		dragging: false,
		error: null,
		errorsVisible: false,
		interviewSavedModalMsg: '',
		loadingInterview: false,
		questions: [],
		savingInterview: false,
		title: '',
		titleError: false,
		titleHighlight: true,
		totalDuration: 0,
		unsavedChanges: false,
		unsavedChangesModalVisible: false
	};

	// ===================================================================================== //
	// ================================= HANDLER FUNCTIONS ================================= //
	// ===================================================================================== //

	handleBlur = () => {
		let { titleError } = this.state;
		this.setState({ titleHighlight: titleError })
	};

	handleCustomQuestion = (order, text) => {
		let { questions } = this.state;
		// Remove id lest we have some big issues
		if (questions[order].id) {
			delete questions[order].id;
		}
		
		questions[order].title = text;
		this.setState({ questions, unsavedChanges: true, errorsVisible: false });
	};

	handleFocus = () => {
		this.setState({ titleHighlight: true });
	};

	handleMouseEnter = (order) => () => {
		this.setState({ activeQuestion: order });
	};

	handleQuestionTimeLimit = (order, value) => {
		let { questions } = this.state;
		questions[order].time_limit = value;
		let totalDuration = questions.reduce((total, question) => question.time_limit + total, 0);
		this.setState({ questions, unsavedChanges: true, totalDuration, errorsVisible: false });
		return true;
	};

	handleQuestionTypeChange = (order, value) => {
		let questions = [...this.state.questions];
		questions[order].questionType = value;
		this.setState({ questions, unsavedChanges: true, errorsVisible: false });
	};

	handleSortEnd = ({ oldIndex, newIndex }) => {
		this.setState(({ questions }) => ({
			dragging: false,
			errorsVisible: false,
			questions: arrayMove(questions, oldIndex, newIndex),
			unsavedChanges: true
		}));
	};

	handleSortStart = () => {
		this.setState({ dragging: true, activeQuestion: null });
	};

	handleTitleChange = (event) => {
		let tempTitle = event.target.value;
		this.setState({ title: tempTitle, unsavedChanges: true, errorsVisible: false, titleError: false });

		if (this.state.collapseCheck.length === 1) {
			setTimeout(() => {
				this.setState(({ title }) => ({ collapseCheck: [title !== ''] }));
			}, 200);
		}
	};
	
	// ===================================================================================== //
	// ============================= GETTER / SETTER FUNCTIONS ============================= //
	// ===================================================================================== //

	getAccountDetails = async () => {
		if (this.props.match.params.clientId) {
			return;
		}
		let [err, session] = await to(get(`/sessions`));
		if (err) {
			console.error(err);
		}
		if (session.status !== 'success') {
			return false;
		}
		let [clientErr, clientInfo] = await to(get(`/clients/${session.user.client_id}`));
		if (clientErr || !clientInfo || (clientInfo.client && clientInfo.client.is_active !== 1)) {
			this.props.history.push('/account');
		}
	};

	setActiveQuestion = (order) => {
		this.setState({ activeQuestion: order });
	};

	setClientId = async () => {
		let { clientId } = this.props.match.params;
		if (clientId) {
			return this.setState({ clientId });
		}

		let [err, session] = await to(get('/sessions'));
		if (err || session.status !== 'success' || !session.user || !session.user.client_id) {
			return console.error(err, session);
		}
		else {
			this.setState({ clientId: session.user.client_id });
		}
	};

	getInterview = async () => {
		this.setState({ loadingInterview: true });
		let { clientId } = this.state;
		if (!clientId) {
			return console.error('Client ID not set');
		}
		let { interviewId } = this.props.match.params;
		get(`/clients/${clientId}/interviews/${interviewId}`).then((json) => {
			if (json.status === "success") {
				this.setInterview(json.interviews[0]);
			}
			this.setState({ loadingInterview: false });
		});
	};

	setInterview = ({ title, questions }) => {
		this.setState({
			title,
			activeQuestion: 0,
			collapseCheck: Array(questions.length).fill(true),
			questions: sortArr(questions.map((question) => ({ ...question, questionType: "Default" })), ['order']),
			totalDuration: questions.reduce((total, question) => { return question.time_limit + total; }, 0)
		});
	};

	setQuestion = (order, text, id) => {
		this.setState(({ questions }) => {
			questions[order].title = text;
			if (id) {
				questions[order].id = id;
			}
			return { questions, unsavedChanges: true, errorsVisible: false };
		});
	};
	
	// ===================================================================================== //
	// ================================== OTHER FUNCTIONS ================================== //
	// ===================================================================================== //
	
	addQuestion = (order) => {
		let { collapseCheck, questions } = this.state;
		questions.splice(order + 1, 0, { ...templateQuestion });
		this.setState({ questions, unsavedChanges: true, activeQuestion: order + 1, errorsVisible: false });
		setTimeout(() => {
			this.setState({ collapseCheck: collapseCheck.concat(true) });
		}, 200);
	};

	checkUnsavedChanges = () => {
		this.setState(
		({ unsavedChanges }) => {
			if (unsavedChanges) {
				return { unsavedChangesModalVisible: true };
			}
			else {
				this.goToInterviewsPage();
			}
		})
	};

	goToInterviewsPage = () => {
		let { clientId } = this.props.match.params;
		let interviewsURL = (clientId ? `/clients/${clientId}/interviews` : '/interviews');
		this.props.history.push(interviewsURL);
	};

	hideModalConfirm = () => {
		this.setState({ confirmModalVisible: false });
	};

	hideModalUnsavedChanges = () => {
		this.setState({ unsavedChangesModalVisible: false })
	};
	
	removeQuestion = (order) => {
		this.setState(({ collapseCheck }) => ({ collapseCheck: collapseCheck.map((col, index) => { return (index !== order); }) }));

		setTimeout(() => {
			this.setState(({ questions, collapseCheck }) => ({
				collapseCheck: collapseCheck.filter((bool, index) => { return (index !== order); }),
				errorsVisible: false,
				questions: questions.filter((question, index) => { return (index !== order); }),
				unsavedChanges: true
			}));
		}, 200);
	};

	// ===================================================================================== //
	// ================================ INTERVIEW FUNCTIONS ================================ //
	// ===================================================================================== //
	
	validateInterview = () => {
		console.log('\nIn validateInterview...');
		let { questions, title } = this.state;
		let questionsValid = (questions.filter((q) => { return (!q.title || !q.time_limit); }).length < 1);
		let titleValid = (title.trim().length > 0);
		
		this.setState({
			confirmModalVisible: (questionsValid && titleValid),
			errorsVisible: !questionsValid,
			titleHighlight: !titleValid,
			titleError: !titleValid,
			unsavedChangesModalVisible: false
		});
	};

	saveInterview = async () => {
		console.log('\nIn saveInterview...');
		let { interviewId } = this.props.match.params;
		let { title, questions, clientId } = this.state;
		let json = null, msg = null;
		
		if (!clientId) {
			this.props.isNotAuthenticated();
			return console.error('Client ID not set');
		}
		this.setState({ savingInterview: true });
		
		if (interviewId) {
			json = await put(`/clients/${clientId}/interviews/${interviewId}`, { title: title.trim(), questions });
		}
		else {
			json = await post(`/clients/${clientId}/interviews`, { title: title.trim(), questions });
		}
		this.setState({ savingInterview: false });
		
		if (json && json.status === 'success') {
			if (interviewId) {
				msg = (json.recorded ? noNewRecordingsUpdateMsg : newRecordingsUpdateMsg);
			}
			else {
				msg = (json.recorded ? noNewRecordingsCreateMsg : newRecordingsCreateMsg);
			}
		}
		else {
			console.error(json);
			msg = saveErrorMsg;
		}
		this.setState({ confirmModalVisible: false, interviewSavedModalMsg: msg, unsavedChangesModalVisible: false });
	};

	// ===================================================================================== //
	// ================================ LIFECYCLE FUNCTIONS ================================ //
	// ===================================================================================== //
	
	componentDidMount() {
		window.addEventListener('mouseup', this.showStyles);
		this.getAccountDetails();
		this.setClientId().then(() => {
			let { interviewId } = this.props.match.params;
			if (interviewId) {
				this.getInterview();
			}
		});
	}

	componentWillUnmount() {
		window.removeEventListener('mouseup', this.showStyles);
	}

	render() {
		let { classes, match } = this.props;
		let { interviewId } = match.params;
		let {
			title,
			titleError,
			questions,
			titleHighlight,
			dragging,
			collapseCheck,
			activeQuestion,
			totalDuration,
			confirmModalVisible,
			loadingInterview,
			savingInterview,
			interviewSavedModalMsg,
			errorsVisible,
			unsavedChangesModalVisible
		} = this.state;
		
		let questionItems = questions.map(({ id, title, time_limit, recording_url }) => ({ id, title, time_limit, recording_url }));
		let currentQuestionIds = questions.filter(({ id }) => id).map(({ id }) => id);
		let saveUpdateError = (interviewSavedModalMsg === saveErrorMsg);
		let interviewSavedModalTitle = `"${title}" interview ${saveUpdateError ? 'will NOT be' : 'has been'} ${interviewId ? 'updated' : 'saved'}!`;
		let confirmModalTxt = `Have you finished ${interviewId ? 'editing your interview' : 'adding all of your questions'}?`;
		
		return (
			<Fragment>
				<Slide direction="left" in={true} mountOnEnter unmountOnExit >
					<div className={classes.root}>
						<div id="add_new_interview_form">
							<div className={classes.header}>
								<Typography className={classes.typog} variant="headline" gutterBottom onClick={this.checkUnsavedChanges} >
									<ArrowBack/>
									Return To Interviews
								</Typography>
								<Button variant="contained" color="primary" className={classes.addQuestionBtn} onClick={() => { this.addQuestion(questions.length - 1); }} >Add A Question</Button>
							</div>

							<Paper className={classes.paper}>
								{!loadingInterview ? (
									<Fragment>
										<div className={classes.padding}>
											<Input
												id="interview_title"
												placeholder="Enter Your Interview Title"
												fullWidth
												onChange={this.handleTitleChange}
												disableUnderline={!titleHighlight}
												className={classes.interviewTitle}
												onFocus={this.handleFocus}
												onBlur={this.handleBlur}
												value={title}
												error={titleError}
											/>
											<Typography variant="caption" gutterBottom align="left" >Total Interview Limit: {formatTime(totalDuration)}/30:00</Typography>
										</div>
										<QuestionsList
											activeQuestion={activeQuestion}
											addQuestion={this.addQuestion}
											collapseCheck={collapseCheck}
											currentQuestionIds={currentQuestionIds}
											dragging={dragging}
											errorsVisible={errorsVisible}
											handleMouseEnter={this.handleMouseEnter}
											handleQuestionTypeChange={this.handleQuestionTypeChange}
											handleTextChange={this.handleCustomQuestion}
											handleTimeLimitChange={this.handleQuestionTimeLimit}
											hideStyles={this.hideStyles}
											items={questionItems}
											lockAxis="y"
											onSortStart={this.handleSortStart}
											onSortEnd={this.handleSortEnd}
											removeQuestion={this.removeQuestion}
											setQuestion={this.setQuestion}
											showStyles={this.showStyles}
											transitionDuration={400}
											useDragHandle={true}
										/>
									</Fragment>
								) : (
									<LoaderImg classes={classes}/>
								)}
							</Paper>
							<Button variant="outlined" color="primary" className={classes.saveInterviewBtn} onClick={this.validateInterview} disabled={!questions.length} >Save Interview</Button>
						</div>
					</div>
				</Slide>
				{
					unsavedChangesModalVisible && 
					<Dialog open={true} onClose={this.hideModalUnsavedChanges} >
					{
						!savingInterview ? 
						<Fragment>
							<DialogContent>
								<DialogContentText>Do you want to save your changes?</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.goToInterviewsPage} color="primary" variant="outlined" >Don't Save</Button>
								<Button onClick={this.validateInterview} color="primary" variant="contained" >Save</Button>
							</DialogActions>
						</Fragment> : 
						<DialogContent>
							<LoaderImg classes={classes}/>
						</DialogContent>
					}
					</Dialog>
				}
				{
					confirmModalVisible && 
					<Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={true} onClose={this.hideModalConfirm} >
						<div className={classes.modal}>
						{
							!savingInterview ? 
							<Fragment>
								<Typography variant="title" id="modal-title">
									{confirmModalTxt}
								</Typography>
								<Typography variant="caption" gutterBottom>
									Click outside the box to cancel
								</Typography>
								<Button variant="contained" color="primary" onClick={this.saveInterview} >Yes, {interviewId ? 'Save' : 'Create'} the Interview!</Button>
							</Fragment> : 
							<LoaderImg classes={classes}/>
						}
						</div>
					</Modal>
				}
				{
					!!interviewSavedModalMsg && 
					<Dialog open={true} onClose={this.goToInterviewsPage} >
						<DialogTitle>{interviewSavedModalTitle}</DialogTitle>
						<DialogContent>
							<DialogContentText>{interviewSavedModalMsg}</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.goToInterviewsPage} color="primary" variant="contained" >Continue to Interviews</Button>
						</DialogActions>
					</Dialog>
				}
			</Fragment>
		);
	}
}

export default withRouter(withStyles(styles)(EditInterviewPage));
