
import React from 'react';
import { Button } from '@material-ui/core';

export default (props) => (
	<Button className={props.className || ''} variant="outlined" color="primary" fullWidth disabled style={{ height: props.height || 'inherent' }}>
		<svg width="80px" height="80px" style={{ position: 'absolute' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" className="lds-ellipsis">
			<circle cx="84" cy="50" r="0" fill="#90CAF9">
				<animate attributeName="r" values="11;0;0;0;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2" repeatCount="indefinite" begin="0s"></animate>
				<animate attributeName="cx" values="84;84;84;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2" repeatCount="indefinite" begin="0s"></animate>
			</circle>
			<circle cx="40.0957" cy="50" r="11" fill="#64B5F6">
				<animate attributeName="r" values="0;11;11;11;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2" repeatCount="indefinite" begin="-1s"></animate>
				<animate attributeName="cx" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2" repeatCount="indefinite" begin="-1s"></animate>
			</circle>
			<circle cx="16" cy="50" r="7.79567" fill="#42A5F5">
				<animate attributeName="r" values="0;11;11;11;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2" repeatCount="indefinite" begin="-0.5s"></animate>
				<animate attributeName="cx" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2" repeatCount="indefinite" begin="-0.5s"></animate>
			</circle>
			<circle cx="84" cy="50" r="3.20433" fill="#2196F3">
				<animate attributeName="r" values="0;11;11;11;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2" repeatCount="indefinite" begin="0s"></animate>
				<animate attributeName="cx" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2" repeatCount="indefinite" begin="0s"></animate>
			</circle>
			<circle cx="74.0957" cy="50" r="11" fill="#90CAF9">
				<animate attributeName="r" values="0;0;11;11;11" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2" repeatCount="indefinite" begin="0s"></animate>
				<animate attributeName="cx" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2" repeatCount="indefinite" begin="0s"></animate>
			</circle>
		</svg>
	</Button>
)
