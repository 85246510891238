import slide1 from './Slide1.png';
import slide2 from './Slide2.png';
import slide3 from './Slide3.png';
import slide4 from './Slide4.png';
import slide5 from './Slide5.png';
import slide6 from './Slide6.png';
import slide7 from './Slide7.png';
import slide8 from './Slide8.png';

const slideObj1 = { src: slide1, title: "Welcome"};
const slideObj2 = { src: slide2, title: "Create an Interview"};
const slideObj3 = { src: slide3, title: "Customize an Interview"};
const slideObj4 = { src: slide4, title: "Add many Interviews"};
const slideObj5 = { src: slide5, title: "Invite to an Interview"};
const slideObj6 = { src: slide6, title: "Candidate Email"};
const slideObj7 = { src: slide7, title: "Automate the Invite"};
const slideObj8 = { src: slide8, title: "See the Results"};

export default {
    slideObj1,
    slideObj2,
    slideObj3,
    slideObj4,
    slideObj5,
    slideObj6,
    slideObj7,
    slideObj8,
}