
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogTitle, DialogContent, FormControl, TextField, FormHelperText, Input, InputAdornment, IconButton } from '@material-ui/core';
import FontAwesome from 'react-fontawesome';
import copy from 'copy-to-clipboard';
import isEmail from 'validator/lib/isEmail';
import { to } from '../Other/utils';
import { post } from '../Other/api';

const styles = theme => ({
	actionBtn: {
		float: 'right'
	},
	emailInput: {
		marginTop: theme.spacing.unit * 2
	},
	inviteLinkLabel: {
		color: [['#000'], '!important']
	}
});

class InterviewInviteDialog extends Component {
	state = {
		toEmail: '',
		emailErr: false,
		sendingInvite: false,
	};

	sendInterviewInvite = async () => {
		let { clientId, interviewId, showInviteSentAlert } = this.props;
		this.setState({ sendingInvite: true });
		
		let [err] = await to(post(`/clients/${clientId}/interviews/${interviewId}/candidates`, { email: this.state.toEmail, firstName: '', lastName: '', phone: '', refId: null }));
		(err ? console.error(err) : showInviteSentAlert());
		this.setState({ toEmail: '', sendingInvite: false });
	};

	validateEmail = () => (
		this.setState(({ toEmail }) => {
			let emailErr = !isEmail(toEmail);
			if (!emailErr) {
				this.sendInterviewInvite();
			}
			return { emailErr };
		})
	);

	copyLink = (interviewURL) => {
		copy(interviewURL);
		this.props.showCopiedLinkAlert();
	};

	enterToEmail = (event) => this.setState({ toEmail: event.target.value });

	render() {
		let { token, hide, classes } = this.props;
		let { toEmail, emailErr, sendingInvite } = this.state;
		let baseURL = 'https://api.dialogg.io';
		if (window.location.hostname.includes('localhost')) {
			baseURL = 'http://localhost:1338';
		}
		else if (window.location.hostname.includes('staging')) {
			baseURL = 'https://staging-api.dialogg.io';
		}
		let interviewURL = `${baseURL}/register?interviewToken=${token}`;
		let inputAdornment = (
			<InputAdornment position="start">
				<IconButton disabled>
					<FontAwesome name="envelope"/>
				</IconButton>
			</InputAdornment>
		);
		return (
			<Dialog open onClose={hide} aria-labelledby="invite-dialog-title" aria-describedby="invite-dialog-description" maxWidth="md">
				<DialogTitle>Invite A Candidate For An Interview</DialogTitle>
				<DialogContent>
					<TextField
						id="invite-link"
						placeholder="Invite Link"
						label="Invite Link"
						fullWidth
						disabled
						value={interviewURL}
						margin="normal"
						variant="outlined"
						InputLabelProps={{ className: classes.inviteLinkLabel }}
					/>
					<Button variant="outlined" color="primary" onClick={() => this.copyLink(interviewURL)} className={classes.actionBtn}>Copy Link</Button>
					<FormControl fullWidth className={classes.emailInput}>
						<Input
							placeholder="Invite by email address..."
							fullWidth
							value={toEmail}
							onChange={this.enterToEmail}
							error={!!emailErr}
							startAdornment={inputAdornment}
						/>
						<FormHelperText error>{emailErr && "Please enter a valid email"}</FormHelperText>
					</FormControl>
					<Button onClick={this.validateEmail} variant="contained" color="primary" className={classes.actionBtn} disabled={sendingInvite}>{sendingInvite ? 'Sending Invite' : 'Send Invite'}</Button>
				</DialogContent>
			</Dialog>
		);
	}
};

export default withStyles(styles)(InterviewInviteDialog);
