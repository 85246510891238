
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@material-ui/core';

class CustomInput extends Component {
	render() {
		return (<Input
			onClick={this.props.onClick}
			placeholder={this.props.placeholder}
			readOnly
			value={this.props.value}
		/>)
	}
}

CustomInput.propTypes = {
	onClick: PropTypes.func,
	placeholder: PropTypes.string,
	value: PropTypes.string
};

export default (CustomInput);
