
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button, Input, Modal, Select, MenuItem, FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { put, get } from '../Other/api';
import { sortArr } from '../Other/utils';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import CustomInput from '../Components/CustomInput';
import "react-datepicker/dist/react-datepicker.css";

const canCopy = document.queryCommandSupported('copy');
const timestampFormat = 'YYYY-MM-DD HH:mm:ss';
let copyTimeout = null;

function styles(theme) {
	return {
		apiCode: {
			float: (canCopy ? 'left' : 'none'),
			width: (canCopy ? '74%' : '100%')
		},
		copyCode: {
			borderBottom:`1px solid #949494`,
			color: '#2196f3',
			cursor: 'pointer',
			float: 'right',
			margin: '11px 0 0 0',
			padding: '0 0 8px 0',
			textAlign: 'right',
			verticalAlign: 'bottom',
			width: '26%',
			
			"&:hover": {
				borderBottom:`2px solid #252525`,
				color: '#732DF3',
				padding: '0 0 7px 0'
			}
		},
		divider: {
			height: theme.spacing.unit * 2
		},
		model: {
			position: 'absolute',
			width: theme.spacing.unit * 50,
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
			padding: theme.spacing.unit * 4,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			textAlign: 'center',
			top: `${50}%`,
			left: `${50}%`,
			transform: `translate(-${50}%, -${50}%)`
		},
		menuItem: {
			textAlign: "left"
		},
		root: {
			width: '100%',
			display: 'flex',
			flexWrap: 'wrap'
		},
		selectEmpty: {
			textAlign: "left",
			marginTop: theme.spacing.unit * 2
		}
	};
}

// =============================================================== //
// =================== EditClientForm Component ================== //
// =============================================================== //

class EditClientForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			copyText: 'COPY CODE',
			errors: {},
			fields: [
			{
				key: "name",
				label: "Company Name",
				error: false,
				required: true,
				value: props.clientName
			}, {
				key: 'address',
				label: 'Address',
				error: false,
				required: true,
				value: props.clientAddress
			}, {
				key: "status",
				label: "Status",
				opts: [],
				error: false,
				required: true,
				value: (props.isRPO ? 'RPO' : props.clientStatus)
			}, {
				key: "billing_option_id",
				label: "# of Employees",
				opts: [],
				error: false,
				required: true,
				value: props.clientBillingOptionId
			}, {
				key: 'allow_invoice',
				label: 'Allow Invoice Billing',
				opts: [['Yes', 'Yes'], ['No', 'No']],
				error: false,
				required: true,
				value: props.clientAllowInvoice
			}, {
				key: "sales_rep_user_id",
				label: "Sales Rep",
				opts: [],
				error: false,
				required: false,
				value: props.salesRepUserId || ""
			}, {
				key: 'expire_date',
				label: 'Expiration Date',
				required: false,
				value: Moment(props.expireDate).toDate()
			}, {
				key: 'api_key',
				label: 'API Key',
				error: false,
				required: true,
				value: props.apiCode
			}]
		};
	}

	loadBillingOptions = () => {
		get("/billing-options", null,
			({ billingOptions }) => this.setState(({ fields }) => ({
				fields: fields.map((field) => (
					field.key === "billing_option_id" ? { ...field, opts: billingOptions.map(({ id, name }) => [id, name]) } : field
				))
			})),
		);
	};

	loadStatusOptions = () => {
		get("/statuses/clients/all", null,
			({ clientStatuses }) => this.setState(({ fields }) => ({
				fields: fields.map((field) => (
					field.key === "status" ? { ...field, opts: clientStatuses.map(({ id, status, label }) => [status, label]) } : field
				))
			})),
		);
	};

	loadSalesReps = () => {
		get("/users", { roleCode: "sales" },
			({ users }) => this.setState(({ fields }) => ({
				fields: fields.map((field) => (
					field.key === "sales_rep_user_id" ? {
						...field,
						opts: [
							[null, ""],
							...sortArr(
								users,
								["first_name", "last_name"],
							).map(({ id, first_name, last_name }) => [id, `${first_name} ${last_name}`]),
						],
					} : field
				))
			})),
		);
	};

	handleApiCopy = (value) => {
		let apiInput = document.getElementById('client_api_key');
		apiInput.select();
		document.execCommand('copy');
		apiInput.selectionEnd = apiInput.selectionStart;
		apiInput.blur();
		this.setState({ copyText: '👍 COPY CODE' });
		
		if (copyTimeout) {
			clearTimeout(copyTimeout);
		}
		copyTimeout = setTimeout(() => {
			this.setState({ copyText: 'COPY CODE' });
		}, 3000);
	};

	handleChange = (event, key) => {
		let { fields } = this.state;
		let newFields = [];
		fields.forEach((field, f) => {
			field.value = (field.key === key ? event.target.value : field.value);
			newFields.push(field);
		});
		this.setState({ fields: newFields });
	};
	
	handleDateChange = (date, key) => {
		let { fields } = this.state;
		let newFields = [];
		fields.forEach((field, f) => {
			field.value = (field.key === key ? date : field.value);
			newFields.push(field);
		});
		this.setState({ fields: newFields });
	};

	handleSubmit = () => {
		let { fields } = this.state;
		let errors = {};
		let hasErrors = false;
		
		fields.forEach(field => {
			if (field.required && !field.value) {
				errors[field.key] = 'This field is required';
				hasErrors = true;
			}
		});
		this.setState({ errors });
		if (hasErrors) {
			return false;
		}
		
		let values = {};
		fields.forEach(({ key, value }) => {
			values[key] = value;
		});
		
		values.allow_invoice = (values.allow_invoice === 'Yes' ? 1 : 0);
		values.expire_date = (values.expire_date ? Moment(values.expire_date).format(timestampFormat) : Moment().add(1, 'months').format(timestampFormat));
		values.is_rpo = (values.status === 'RPO' ? 1 : 0);
		
		if (!values['sales_rep_user_id']) {
			values['sales_rep_user_id'] = null;
		}
		let { clientId, onSave } = this.props;
		put(`/clients/${clientId}`, values, () => { return onSave(); });
	};

// =============================================================== //
// ======================= Lifecycle Hooks ======================= //
// =============================================================== //

	componentDidMount() {
		this.loadBillingOptions();
		this.loadStatusOptions();
		this.loadSalesReps();
	}
	
	componentWillUnmount() {
		if (copyTimeout) {
			clearTimeout(copyTimeout);
		}
	}

	render() {
		let { classes } = this.props;
		let { copyText, errors, fields } = this.state;
		let formTitle = "Edit Client";
		
		return (
			<Modal aria-labelledby={formTitle} aria-describedby={formTitle} open={this.props.open} onClose={this.props.onClose} >
				<div className={classes.model}>
					<Typography variant="display1" gutterBottom>
						{formTitle}
					</Typography>
					<form className={classes.root} autoComplete="off">
					{
						fields.map(({ key, label, opts, error, value }) => (
							<FormControl key={key} fullWidth>
							{
								key === 'expire_date' ?
									<DatePicker
										customInput={<CustomInput />}
										dateFormat="MMMM d, yyyy h:mm aa"
										disabledKeyboardNavigation
										error={!!errors[key]}
										fullWidth
										key={`${key}_datePicker`}
										minDate={new Date()}
										onChange={(date) => { this.handleDateChange(date, key); }}
										placeholderText={label}
										selected={value}
										shouldCloseOnSelect={true}
										showTimeSelect
										timeFormat="h aa"
										timeIntervals={60}
										timeCaption="Time"
										todayButton={"Today"}
									/> :
								(key === 'api_key' ? 
									<div>
										<Input
											className={classes.apiCode}
											error={!!errors[key]}
											id={`client_${key}`}
											key={key}
											readOnly
											ref={(input) => this.apiInputRef = input}
											value={value || ""}
										/>
										{
											canCopy && <FormHelperText className={classes.copyCode} color="primary" onClick={(event) => { this.handleApiCopy(value); }}>{copyText}</FormHelperText>
										}
									</div> :
									(!opts ? 
										<Input
											error={!!errors[key]}
											fullWidth
											id={`edit-client-${key}`}
											key={key}
											onChange={(e) => { this.handleChange(e, key); }}
											placeholder={label}
											value={value || ""}
										/> :
										<Fragment>
										{ !value && <InputLabel htmlFor={`${key}-simple`}>{label}</InputLabel> }
											<Select
												className={classes.selectEmpty}
												error={!!errors[key]}
												fullWidth
												inputProps={{ id: `${key}-simple`, name: key }}
												onChange={(e) => { this.handleChange(e, key); }}
												value={value || ""}
											>
											{
												opts.map(([value, label]) => (
												<MenuItem className={classes.menuItem} key={value} value={value} >{label}</MenuItem>))
											}
											</Select>
											<div className={classes.divider}/>
										</Fragment>
									)
								)
							}
								<FormHelperText error className={classes.errorText}>
									{errors[key]}
								</FormHelperText>
							</FormControl>
						))
					}
					</form>
					<Button
						color="primary"
						fullWidth
						onClick={this.handleSubmit}
						variant="contained"
					>
						Save Client
					</Button>
				</div>
			</Modal>
		);
	}
}

export default withStyles(styles)(EditClientForm);
