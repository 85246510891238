
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { ArrowBack, Close } from '@material-ui/icons';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Input, Paper, Slide, Typography } from '@material-ui/core';
import { get, post } from '../Other/api';
import { to } from '../Other/utils';
import loaderImg from '../assets/images/dribble-loader.gif';

const ctx = document.createElement('canvas').getContext('2d');
let fontFamily = '';

function styles(theme) {
	fontFamily = theme.typography.fontFamily;
	return {
		dialogActions: {
			borderTop: '1px solid lightgray',
			margin: 0,
			padding: theme.spacing.unit * 2
		},
		dialogClose: {
			color: theme.palette.text.primary,
			float: 'right',
			height: '56px',
			width: '56px'
		},
		dialogHeader: {
			display: 'inline-block'
		},
		dialogIcon: {
			height: '24px',
			width: '24px'
		},
		dialogItem: {
			margin: 0,
			padding: `0 ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`
		},
		dialogText: {
			color: theme.palette.text.primary
		},
		dialogTitle: {
			float: 'left',
			margin: 0,
			padding: theme.spacing.unit * 2,
			width: `calc(100% - ${56 + (theme.spacing.unit * 4)}px) !important`
		},
		fillerDiv: {
			height: '56px !important',
			width: '106px !important'
		},
		gridContainer: {
			display: 'flex',
			flexDirection: 'column'
		},
		gridItem: {
			borderTop: '1px solid lightgray',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			padding: theme.spacing.unit
		},
		guideItem: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			padding: theme.spacing.unit
		},
		guideTitle: {
			fontSize: '20px',
			fontWeight: 'bold',
			margin: theme.spacing.unit,
			overflowX: 'hidden',
			whiteSpace: 'pre'
		},
		headerTitle: {
			alignItems: 'left',
			display: 'flex',
			fontSize: '32px',
			width: '100%'
		},
		loaderContainer: {
			height: '600px',
			width: '100%'
		},
		loaderContainer2: {
			height: '150px',
			width: '200px'
		},
		loaderImg: {
			height: '75px',
			left: '50%',
			position: 'fixed',
			textAlign: 'center',
			top: '50%',
			transform: `translate(-50%, -50%)`,
			width: '100px'
		},
		noContentTitle: {
			color: 'gray',
			fontSize: '20px',
			margin: theme.spacing.unit
		},
		paper: {
			color: theme.palette.text.secondary,
			paddingBottom: 5,
			width: '100%'
		},
		pointer: {
			'&:hover': {
				cursor: 'pointer'
			}
		},
		root: {
			boxSizing: 'border-box',
			flexGrow: 1,
			margin: 'auto',
			maxWidth: 1200,
			padding: theme.spacing.unit * 3,
			width: '100%'
		},
		searchBar: {
			margin: theme.spacing.unit,
			marginBottom: theme.spacing.unit * 2,
			marginTop: theme.spacing.unit * 2
		},
		templateButton: {
			margin: theme.spacing.unit
		},
		templateLink: {
			fontSize: '18px',
			margin: theme.spacing.unit,
			textAlign: 'left',
			textDecoration: 'underline',
			overflowX: 'hidden',
			whiteSpace: 'pre',
			'&:hover': {
				color: theme.palette.primary.main,
				cursor: 'pointer'
			}
		},
		templateTitle: {
			fontSize: '20px',
			margin: theme.spacing.unit,
			overflowX: 'hidden',
			whiteSpace: 'pre'
		},
		typography: {
			alignItems: 'center',
			display: 'flex',
			'&:hover': {
				color: theme.palette.primary.main,
				cursor: 'pointer'
			}
		}
	};
}

class TemplateInterviewPage extends Component {
	state = {
		cloningTemplate: false,
		errorDialogText: 'We apologize, but an error occurred while attempting to add this template interview to your account. Please try again later.',
		errorDialogTitle: 'An Error Occurred',
		loadingTemplates: true,
		previewIndex: -1,
		selectError: false,
		showDialog: false,
		templates: []
	};
	clientID = 0;
	originals = [];
	searchTimeout = null;
	searchValue = '';
	
	// ========================================================================================== //
	// =================================== COMPONENT FUNCTIONS ================================== //
	// ========================================================================================== //
	
	getAccountDetails = async () => {
		if (!this.clientID) {
			return console.error('Client ID not set');
		}
		
		let [clientErr, clientInfo] = await to(get(`/clients/${this.clientID}`));
		if (clientErr || !clientInfo || (clientInfo.client && clientInfo.client.is_active !== 1)) {
			this.props.history.push('/account');
		}
	};

	getInterviewTemplates = async () => {
		get('/interview-templates').then((json) => {
			if (json.status === "success") {
				this.originals = json.templates;
				this.setState({ templates: json.templates });
			}
			this.setState({ loadingTemplates: false });
		});
	};

	goToInterviewsPage = () => {
		let { clientId } = this.props.match.params;
		let interviewsURL = (clientId ? `/clients/${clientId}/interviews` : '/interviews');
		this.props.history.push(interviewsURL);
	};
	
	handleSearchKeyUp = (event) => {
		this.searchValue = `${event.target.value}`.trim();
    	
    	if (this.searchTimeout) {
			clearTimeout(this.searchTimeout);
			this.searchTimeout = null;
		}
		
		if (!this.searchValue) {
			this.setState({ templates: this.originals });
		}
		else {
			this.searchTimeout = setTimeout(() => {
				this.searchTimeout = null;
				let filtered = [];
				
				for (let t = 0; t < this.originals.length; t++) {
					if (this.originals[t].title.toLowerCase().includes(this.searchValue.toLowerCase())) {
						filtered.push(this.originals[t]);
					}
				}
				
				if (JSON.stringify(filtered) !== JSON.stringify(this.state.templates)) {
					this.setState({ templates: filtered });
				}
			}, 200);
		}
	};
	
	previewTemplate = (id) => {
		let index = this.state.templates.map((template, t) => { return template.id; }).indexOf(id);
		this.setState({ previewIndex: index, showDialog: (index > -1) });
	};
	
	previewClose = () => {
		this.setState({ previewIndex: -1, selectError: false, showDialog: false });
	};
	
	selectTemplate = (id) => {
		this.setState({ cloningTemplate: true, showDialog: true });
		post('/interview-templates', { clientID: this.clientID, templateID: id }).then(this.selectTemplateResponse).catch(this.selectTemplateResponse);
	};
	
	selectTemplateResponse = (res) => {
		if (res.status === 'success') {
			this.goToInterviewsPage();
		}
		else {
			console.error(JSON.stringify(res));
			this.setState({ cloningTemplate: false, selectError: true, showDialog: true });
		}
	};

	setClientId = async () => {
		let { clientId } = this.props.match.params;
		if (clientId) {
			this.clientID = clientId;
			return;
		}

		let [err, session] = await to(get('/sessions'));
		if (err || session.status !== 'success' || !session.user || !session.user.client_id) {
			return console.error(err, session);
		}
		else {
			this.clientID = session.user.client_id;
		}
	};
	
	// ========================================================================================== //
	// ===================================== HOOK FUNCTIONS ===================================== //
	// ========================================================================================== //
	
	componentDidMount() {
		this.setClientId().then(() => {
			this.getAccountDetails();
			this.getInterviewTemplates();
		});
		ctx.font = `20px ${fontFamily}`;
		window.addEventListener('mouseup', this.showStyles);
	}

	componentWillUnmount() {
		window.removeEventListener('mouseup', this.showStyles);
	}

	render() {
		let { classes } = this.props;
		let { cloningTemplate, errorDialogText, errorDialogTitle, loadingTemplates, previewIndex, selectError, showDialog, templates } = this.state;
		let title = 'Title';
		let titleWidth = ctx.measureText(title).width;
		let maxWidth = Math.floor(titleWidth);
		
		for (let a = 0; a < templates.length; a++){
			let widthA = ctx.measureText(templates[a].title).width;
			if (maxWidth < widthA){
				maxWidth = Math.floor(widthA);
			}
		}
		
		while (titleWidth < maxWidth) {
			title += ' ';
			titleWidth = ctx.measureText(title).width;
		}
		
		for (let b = 0; b < templates.length; b++) {
			templates[b].adjustedTitle = templates[b].title;
			let widthB = ctx.measureText(templates[b].adjustedTitle).width;
			
			while (widthB < maxWidth) {
				templates[b].adjustedTitle += ' ';
				widthB = ctx.measureText(templates[b].adjustedTitle).width;
			}
		}
		
		return (
			<Fragment>
				<Slide direction="left" in={true} mountOnEnter unmountOnExit >
					<div className={classes.root}>
						<div id="add_new_interview_form">
							<Typography className={classes.typography} variant="headline" gutterBottom onClick={this.goToInterviewsPage} >
								<ArrowBack/>
								Return To Interviews
							</Typography>
							
							<Typography className={classes.headerTitle} variant="headline" gutterBottom>
								Select From Template Interviews
							</Typography>
							{
								loadingTemplates ?
								<div className={classes.loaderContainer}>
									<img alt="processing request" className={classes.loaderImg} src={loaderImg} />
								</div> :
								<Paper className={classes.paper}>
									<Grid className={classes.gridContainer} container >
										<Grid className={classes.guideItem} alignItems="flex-end" container item xs={8} >
											<Input className={classes.searchBar} id="templateSearch" placeholder="🔍 Search Template Interviews" fullWidth onKeyUp={this.handleSearchKeyUp} />
										</Grid>
										<Grid className={classes.guideItem} alignItems="flex-end" container item xs={12} >
											<Typography className={classes.guideTitle} >{title}</Typography>
											<Typography className={classes.guideTitle} >Number of Questions</Typography>
											<div className={classes.fillerDiv}></div>
										</Grid>
										{
											!!templates.length ?
											templates.map((template, index) => {
												return <Grid className={classes.gridItem} alignItems="center" key={index} container item xs={12} >
													<Typography className={classes.templateTitle} >{template.adjustedTitle}</Typography>
													<Typography className={classes.templateLink} onClick={() => { this.previewTemplate(template.id); }}>{template.questions.length} Questions (View)</Typography>
													<Button variant="contained" color="primary" className={classes.templateButton} onClick={() => { this.selectTemplate(template.id); }}>Select</Button>
												</Grid>;
											}) :
											<Grid className={classes.gridItem} alignItems="center" container item xs={12} >
												<Typography className={classes.noContentTitle} noWrap >No matches available.</Typography>
											</Grid>
										}
									</Grid>
								</Paper>
							}
						</div>
					</div>
				</Slide>
				{
					showDialog &&
					<Dialog open={true}>
					{
						cloningTemplate ?
						<DialogContent>
							<div className={classes.loaderContainer2}>
								<img alt="processing request" className={classes.loaderImg} src={loaderImg}/>
							</div>
						</DialogContent> :
							
						<Fragment>
							<div className={classes.dialogHeader}>
								<DialogTitle className={classes.dialogTitle}>{selectError ? errorDialogTitle : templates[previewIndex].title}</DialogTitle>

								<IconButton aria-label="delete" className={classes.dialogClose} onClick={this.previewClose}>
									<Close className={classes.dialogIcon}/>
								</IconButton>
							</div>

							<DialogContent className={classes.dialogItem}>
							{
								selectError ? 
								<DialogContentText className={classes.dialogText}>{errorDialogText}</DialogContentText> :
								
								templates[previewIndex].questions.map((question, index) => {
									return <DialogContentText className={classes.dialogText} key={index}>{`${index + 1}. ${question.title}`}</DialogContentText>;
								})
							}
							</DialogContent>
							{
								selectError ? 
								<DialogActions className={classes.dialogActions}>
									<Button onClick={this.goToInterviewsPage} color="primary" variant="contained" >Return to Interviews</Button>
								</DialogActions> :
									
								<DialogActions className={classes.dialogActions}>
									<Button color="primary" variant="outlined" onClick={this.previewClose}>Cancel</Button>
									<Button color="primary" variant="contained" onClick={() => { this.selectTemplate(templates[previewIndex].id); }}>Select</Button>
								</DialogActions>
							}
						</Fragment>
					}
					</Dialog>
				}
			</Fragment>
		);
	}
}

export default withRouter(withStyles(styles)(TemplateInterviewPage));
