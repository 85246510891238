
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Typography, Table, TableBody, TableCell, TableHead, TableRow, Slide, Button } from '@material-ui/core';
import FontAwesome from 'react-fontawesome';
import { get } from '../Other/api';
import { ClientUserRow } from '../Components';
import { AddClientUserDialog } from '../Dialogs';

const styles = theme => ({
	table: {
		minWidth: 700
	},
	editCell: {
		width: 25,
		paddingRight: 10,
		paddingLeft: 10
	},
	addUserButton: {
		position: 'absolute',
		width: theme.spacing.unit * 50,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing.unit * 4,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		top: `${50}%`,
		left: `${50}%`,
		transform: `translate(-${50}%, -${50}%)`
	},
	addUserModal: {
		position: 'absolute',
		width: theme.spacing.unit * 50,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing.unit * 4,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		top: `${50}%`,
		left: `${50}%`,
		transform: `translate(-${50}%, -${50}%)`,
	}
});

class Users extends Component {
	constructor(props) {
		super(props);
		this.state = {
			users: [],
			client: {},
			showUserDetail: false,
			createUserModalOpen: false,
		};
	}

	closeCreateUserModal = () => {
		this.getUsers();
		this.setState({ createUserModalOpen: false });
	};

	back = () => {
		this.props.history.goBack();
	};

	getClient = () => {
		let { clientId } = this.props.match.params;
		get(`/clients/${clientId}`, null,
			({ client }) => { return this.setState({ client }); },
			() => { return this.props.isNotAuthenticated(); }
		);
	};

	getUsers = () => {
		let { clientId } = this.props.match.params;
		get(`/clients/${clientId}/users`, null,
			({ users }) => { return this.setState({ users }); },
			() => { return this.props.isNotAuthenticated(); }
		);
	};

	componentDidMount() {
		this.getClient();
		this.getUsers();
	}

	handleTextChange = name => event => {
		this.setState({
			[name]: event.target.value,
		});
	};

	toggleCreateModal = () => {
		this.setState({ createUserModalOpen: true });
	};

	render() {
		let { classes, match } = this.props;
		let { clientId } = match.params;
		let { client, users, createUserModalOpen } = this.state;
		return (
			<Slide direction="left" in={true} mountOnEnter unmountOnExit>
				<Fragment>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>
									<Typography className={classes.pointer} variant="headline" gutterBottom onClick={this.back}>
										<FontAwesome name="arrow-left"/>
									</Typography>
								</TableCell>
								<TableCell colSpan={3}>
									<Typography className={classes.pointer} variant="headline" gutterBottom onClick={this.back}>
										{client.name}'s Users
									</Typography>
								</TableCell>
								<TableCell>
									<Button variant="fab" color="primary" aria-label="Add" className={classes.button} onClick={this.toggleCreateModal}>
										<FontAwesome name='plus'/>
									</Button>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Edit</TableCell>
								<TableCell>Name</TableCell>
								<TableCell>Email</TableCell>
								<TableCell>Roles</TableCell>
								<TableCell>User Id</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{users.map(user => (
								<ClientUserRow
									clientID={clientId}
									key={user.id}
									info={user}
									className={classes.editCell}
									refreshUsers={this.getUsers}
								/>
							))}
						</TableBody>
					</Table>
					<AddClientUserDialog
						open={createUserModalOpen}
						client={client}
						onClose={this.closeCreateUserModal}
					/>
				</Fragment>
			</Slide>
		);
	}
}

export default withRouter(withStyles(styles)(Users));
