
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Table, TableBody, TableCell, TableHead, Button, TableRow, Slide, FormControl, InputLabel, Select, Input, MenuItem } from '@material-ui/core';
import FontAwesome from 'react-fontawesome';
import { ClientRow, CreateClientForm } from '../Components';
import { get } from '../Other/api';
import { sortArr } from '../Other/utils';

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		overflowX: 'auto'
	},
	button: {
		margin: theme.spacing.unit
	},
	editCell: {
		width: 25,
		paddingRight: 10,
		paddingLeft: 10
	},
	controlContainer: {
		display: 'flex'
	},
	btnContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: 28,
		marginLeft: 'auto'
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120
	}
});

class Clients extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchKey: '',
			searchString: '',
			clients: [],
			selectedClient: null,
			showClientDetail: false,
			order: 'desc',
			orderBy: 1,
			visible: true,
			formDialogOpen: false,
			salesReps: [],
			filterSalesRepId: 'any',
			filterStatus: 'any',
		};
	}

	openEditModal = client => () => {
		this.setState({ selectedClient: client, editDialogOpen: true });
	};

	closeEditModal = () => {
		this.setState({ editDialogOpen: false });
	};
	handleSelectChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleTextChange = name => event => {
		this.setState({
			[name]: event.target.value,
		});
	};

	getClients = () => {
		let { filterSalesRepId, filterStatus } = this.state;
		let params = {};
		if (filterSalesRepId === 'unassigned') {
			params.salesRepUserId = '';
		}
		else if (filterSalesRepId !== 'any') {
			params.salesRepUserId = filterSalesRepId;
		}
		if (filterStatus !== 'any') {
			params.status = filterStatus;
		}
		get('/clients', params,
			({ clients }) => this.setState({ clients }),
			this.props.isNotAuthenticated);
	};

	handleClose = (value) => {
		this.setState({ selectedValue: value, editDialogOpen: false });
	};

	handleFormDialogClose = () => {
		this.getClients();
		this.setState({
			formDialogOpen: false
		});
	};

	componentDidMount() {
		this.loadSalesReps();
	}

	toggleFormDialog = () => {
		this.setState({ formDialogOpen: true });
	};

	setFilterSalesRepId = ({ target }) => {
		this.setState(
			{ filterSalesRepId: target.value },
			this.getClients,
		);
	};

	setFilterStatus = ({ target }) => this.setState(
		{ filterStatus: target.value },
		this.getClients,
	);

	loadSalesReps = () => {
		let { userId } = this.props;
		get("/users", { roleCode: "sales" },
			({ users }) => this.setState({
				salesReps: sortArr(users, ["first_name", "last_name"],),
				filterSalesRepId: users.find(({ id }) => id === userId) ? this.props.userId : "any",
			}, this.getClients),
			this.props.isNotAuthenticated);
	};

	render() {
		let { classes } = this.props;
		let { salesReps, filterSalesRepId, filterStatus, formDialogOpen, clients } = this.state;
		let clientStatuses = ['Trial', 'Demo', 'Standard', 'Expired'];
		return (
			<Fragment>
				<Slide direction="right" in={this.state.visible} mountOnEnter unmountOnExit>
					<Fragment>
						<Table className={classes.table}>
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography className={classes.pointer} variant="headline" gutterBottom onClick={this.back}>
											Clients
										</Typography>
									</TableCell>
									<TableCell colSpan={9}>
										<FormControl className={classes.formControl}>
											<InputLabel htmlFor="salesRep-helper">Sales Rep</InputLabel>
											<Select
												value={filterSalesRepId || 'any'}
												onChange={this.setFilterSalesRepId}
												input={(
													<Input name="salesRep" id="salesRepDropdown-helper"/>
												)}
											>
												<MenuItem value="any">Any</MenuItem>
												<MenuItem value="unassigned">Unassigned</MenuItem>
												{salesReps.map(({ id, first_name, last_name }) => (
													<MenuItem value={id} key={id}>
														{`${first_name} ${last_name}`}
													</MenuItem>
												))}
											</Select>
										</FormControl>
										<FormControl className={classes.formControl}>
											<InputLabel htmlFor="status-helper">Status</InputLabel>
											<Select
												value={filterStatus || 'any'}
												onChange={this.setFilterStatus}
												input={(
													<Input name="status" id="status-helper"/>
												)}
											>
												<MenuItem value="any">Any</MenuItem>
												{clientStatuses.map((status) => (
													<MenuItem value={status} key={status}>
														{status}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</TableCell>
									<TableCell>
										<Button variant="fab" color="primary" aria-label="Add" className={classes.button} onClick={this.toggleFormDialog}>
											<FontAwesome name='plus'/>
										</Button>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Edit</TableCell>
									<TableCell>Client Id</TableCell>
									<TableCell>Name</TableCell>
									<TableCell>Main Contact</TableCell>
									<TableCell>Sales Rep</TableCell>
									<TableCell>Status</TableCell>
									<TableCell>Date Expires</TableCell>
									<TableCell># of Employees</TableCell>
									<TableCell>Allow Invoices</TableCell>
									<TableCell>RPO</TableCell>
									<TableCell>Date Created</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{clients.map(client =>
									<ClientRow
										key={client.id}
										info={client}
										className={classes.editCell}
										openEditModal={this.openEditModal(client)}
										onClientUpdate={this.getClients}
									/>
								)}
							</TableBody>
						</Table>
						{
							formDialogOpen && (
							<CreateClientForm
								open
								onClose={this.handleFormDialogClose}
								salesRepUserId={isNaN(filterSalesRepId) ? null : filterSalesRepId}
							/>)
						}
					</Fragment>
				</Slide>
			</Fragment>
		);
	}
}

export default withStyles(styles)(Clients);
