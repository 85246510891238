
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography, Divider, Button, Tooltip, Grow } from '@material-ui/core';

const styles = theme => ({
	container: {
		padding: theme.spacing.unit * 2,
		paddingBottom: 0,
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	buttonsContainer: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	button: {
		margin: theme.spacing.unit,
		marginTop: 5
	},
	txtButton: {
		margin: theme.spacing.unit,
		marginLeft: 0
	},
	archivedCardText: {
		color: '#d0021b'
	}
});

class ArchivedInterviewCard extends Component {
	render() {
		let {
			classes,
			index,
			restoreArchivedInterview,
			editInterview,
			...interview
		} = this.props;
		return (
			<Grow in={true}>
				<Paper className={classes.container}>
					<Typography
						variant="subheading"
						gutterBottom
						align="left"
						className={classes.archivedCardText}
					>
						{interview.title}
					</Typography>
					<Typography
						variant="caption"
						gutterBottom
						align="left"
					>
						{interview.questions.length} Questions
					</Typography>
					<Divider/>
					<div className={classes.buttonsContainer}>
						<Tooltip id="tooltip-invite" title="Edit the interview">
							<Button
								color="primary"
								className={classes.txtButton}
								onClick={editInterview}
							>
								Edit
							</Button>
						</Tooltip>
						<Tooltip id="tooltip-invite" title="Unarchive the interview">
							<Button
								color="primary"
								className={classes.txtButton}
								onClick={() => restoreArchivedInterview(index)}
							>
								Restore
							</Button>
						</Tooltip>
					</div>
				</Paper>
			</Grow>
		);
	}
}

export default withStyles(styles)(ArchivedInterviewCard);
