
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Typography, Table, TableBody, TableCell, TableHead, TableRow, Slide, Button, Select, MenuItem, InputLabel, Input, FormControl, TextField } from '@material-ui/core';
import FontAwesome from 'react-fontawesome';
import { get } from '../Other/api';
import { UserRow } from '../Components';
import { CreateUserDialog } from '../Dialogs';

const styles = theme => ({
	table: {
		minWidth: 700
	},
	editCell: {
		width: 25,
		paddingRight: 10,
		paddingLeft: 10
	},
	addUserButton: {
		position: 'absolute',
		width: theme.spacing.unit * 50,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing.unit * 4,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		top: `${50}%`,
		left: `${50}%`,
		transform: `translate(-${50}%, -${50}%)`
	},
	addUserModal: {
		position: 'absolute',
		width: theme.spacing.unit * 50,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing.unit * 4,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		top: `${50}%`,
		left: `${50}%`,
		transform: `translate(-${50}%, -${50}%)`
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120
	},
	controlContainer: {
		display: 'flex'
	},
});

class Users extends Component {
	constructor(props) {
		super(props);
		this.state = {
			users: [],
			showUserDetail: false,
			createUserModalOpen: false,
			roles: [],
			filterBy: "role",
			filterRole: "admin",
			filterEmail: "",
		};
	}

	closeCreateUserModal = () => {
		this.getUsers();
		this.setState({ createUserModalOpen: false });
	};

	getUsers = () => {
		let {
			filterBy,
			filterRole,
			filterEmail,
		} = this.state;
		let params = {};
		if (filterBy === "role" && filterRole) {
			params = { 'roleCode': filterRole };
		}
		if (filterBy === "email" && filterEmail) {
			params = { 'email': filterEmail };
		}
		get(
			"/users",
			params,
			({ users }) => this.setState({ users }),
			() => { return this.props.isNotAuthenticated(); },
		);
	};

	componentDidMount() {
		this.loadRoles().then(() => this.getUsers());
	}

	handleTextChange = name => event => {
		this.setState({
			[name]: event.target.value,
		});
	};

	toggleCreateModal = () => {
		this.setState({ createUserModalOpen: true });
	};

	loadRoles = () => get(
		"/roles",
		null,
		({ roles }) => this.setState({ roles }),
		() => { return this.props.isNotAuthenticated(); },
	);

	setFilterBy = ({ target }) => {
		this.setState({
			filterBy: target.value,
			filterEmail: "",
			filterRole: ""
		}, this.getUsers);
	};

	setFilterRole = ({ target }) => {
		this.setState({ filterRole: target.value }, this.getUsers);
	};

	setFilterEmail = ({ target }) => {
		this.setState({ filterEmail: target.value }, this.getUsers);
	};

	render() {
		let { classes } = this.props;
		let {
			filterRole,
			filterBy,
			roles,
			users
		} = this.state;
		
		return (
			<Slide direction="left" in={true} mountOnEnter unmountOnExit>
				<Fragment>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>
									<Typography className={classes.pointer} variant="headline" gutterBottom>
										Users
									</Typography>
								</TableCell>
								<TableCell colSpan={3}>
									<div className={classes.controlContainer}>
										<div className={classes.formControl}>
											<FormControl className={classes.formControl}>
												<InputLabel htmlFor="searchKey-helper">filter by</InputLabel>
												<Select
													value={filterBy}
													onChange={this.setFilterBy}
													input={<Input name="searchKey" id="searchKey-helper"/>}
												>
													<MenuItem value={'role'}>Role</MenuItem>
													<MenuItem value={'email'}>Email</MenuItem>
												</Select>
											</FormControl>
											{(filterBy === 'role'
													? <FormControl className={classes.formControl}>
														<InputLabel htmlFor="searchKey-helper">filter by</InputLabel>
														<Select
															value={filterRole || ""}
															onChange={this.setFilterRole}
															input={
																<Input name="searchString" id="searchStringDropdown-helper"/>}
														>
															<MenuItem value="">None</MenuItem>
															{roles.map((role) => (
																<MenuItem value={role.code} key={role.code}>{role.title}</MenuItem>
															))}
														</Select>
													</FormControl>
													: <FormControl>
														<TextField
															label=""
															className={classes.textField}
															type={'search'}
															onChange={this.setFilterEmail}
															autoComplete="current-search"
															margin="normal"
														/>
													</FormControl>
											)}
										</div>
									</div>
								</TableCell>
								<TableCell>
									<Button variant="fab" color="primary" aria-label="Add" className={classes.button} onClick={this.toggleCreateModal}>
										<FontAwesome name="plus"/>
									</Button>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Edit</TableCell>
								<TableCell>Name</TableCell>
								<TableCell>Email</TableCell>
								<TableCell>Roles</TableCell>
								<TableCell>User Id</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{users.map(user => (
								<UserRow
									key={user.id}
									info={user}
									className={classes.editCell}
									refreshUsers={this.getUsers}
								/>
							))}
						</TableBody>
					</Table>
					<CreateUserDialog
						open={this.state.createUserModalOpen}
						onClose={this.closeCreateUserModal}
					/>
				</Fragment>
			</Slide>
		);
	}
}

export default withRouter(withStyles(styles)(Users));
