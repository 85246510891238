
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Paper, TextField, Typography, Button, FormControl, FormControlLabel, FormLabel, RadioGroup, Radio } from '@material-ui/core';
import { post, get } from '../Other/api';

const styles = theme => ({
	container: {
		boxSizing: 'border-box',
		width: '100%',
		maxWidth: 600,
		margin: 'auto',
		marginTop: 50,
		padding: 30,
		flexGrow: 1
	},
	button: {
		marginTop: theme.spacing.unit * 3
	},
	forgot: {
		color: '#BDBDBD',
		cursor: 'pointer',
		font: 'Regular 12px/14px Work Sans',
		marginTop: theme.spacing.unit * 3,
		textAlign: 'center',
		textDecoration: 'underline',
		'&:hover': {
			color: '#2D64AA'
		}
	},
	auth_box: {
		font: "400 18.3333px Arial"
	},
	inner_auth_box: {
		font: "400 25px Arial"
	},
	center: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'

	}

});

class EmailBox extends Component {
	constructor(props) {
		super(props);
	}

	hideEmail() {
		var name   = this.props.email.substring(0, this.props.email.lastIndexOf("@"));
		var domain = this.props.email.substring(this.props.email.lastIndexOf("@") +1);
		return name.substring(0,1) + Array(name.length).join('*') + '@' + domain.substring(0, 1) + Array(domain.length).join('*');
	}
	render() {
		return (
			<div style={{margin: "10px 20px"}}>
				<Typography>
				<i>Click the button to send a code to {this.hideEmail()}</i>
				</Typography>
			</div>
		)
	}
}

class SmsBox extends Component {
	constructor(props) {
		super(props);

		let text = this.props.phone.length 
			? "Click the button to send a text with a secure code to " + this.getHiddenPhone(this.props.phone) + ". (Standard text messaging rates and charges may apply)"
			: "No phone number provided";
		this.state = {
			text: text
		}
	}

	getHiddenPhone(phone) {
		console.log(phone);
		let hiddenCharacters = phone.length - 4;
		return Array(hiddenCharacters + 1).join('*') + phone.substring(hiddenCharacters);
	}

	render() {
		let {classes} = this.props;
		return (
			<div style={{margin: "10px 20px"}}>
				<Typography><i>{this.state.text}</i></Typography> 		
			</div>
		)
	}
}
class TFASelection extends Component {
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		let user = JSON.parse(sessionStorage.getItem("login_user"));
		this.state = {
			code: '',
			showErrorMessage: false,
			disabledButton: true,
			selectedOption: "",
			user: user
		};
	}

	/**
	 * Disable the button if they have selected an option but don't have a value for that medium.
	 */
	checkValidMedium() {
		let isDisabled = false;
		if (this.state.selectedOption === "email" && !this.state.user.email) {
			isDisabled = true;
		} else if (this.state.selectedOption === "sms" && !this.state.user.phone) {
			isDisabled = true;
		}

		console.log(isDisabled);
		console.log(this.state.selectedOption);
		console.log(this.state.user.phone);
		this.setState({
			disabledButton: isDisabled
		});
	}

	onChange(event) {
		
		this.setState({
		  selectedOption: event.target.value,
		  disabledButton: false
		}, this.checkValidMedium);

		//this.checkValidMedium();
	}

	submitHandle = async () => {
		if (!this.state.selectedOption) {
			return;
		}

		// Post the data to the api server.abs
		this.setState({"disableButton": "disabled" });
		let user_id = sessionStorage.getItem("user_id");
		post('/sendTfaCode/', { user_id, medium: this.state.selectedOption },
			(result) => {
				// Set result token
				//if (result.status) {
					this.props.history.push('/validate');
				//} else {
					// clear the code and show the error message.
					//return false;
				//}
			},
			(result) => {
				this.props.history.push('/validate');
				//console.log(result);
				//this.setState({"disableButton": "" })
				//this.setState({ showErrorMessage: true});
			}
		);
		// forward the user to the component that will let them enter their code.
	};
	
	render() {
		let { classes, authenticateHandle } = this.props;
		return (
			<Paper className={classes.container}>
				<form className={`tfa_selection`}>
					
					<h2 style={{ textAlign: "center" }}>Two Step Verification</h2>

					<Typography style={{ marginBottom: "20px" }}>
						For your security, we need to confirm your identity by sending you a secure code through your email address or cell phone. Please select a method below on how you would like to receive your code.
					</Typography>
					<div className={classes.auth_box} style={{marginBottom: "10px"}}>
						<div style={{cursor: "default"}} onclick="sendAuthOption(this)">
							<input type="radio" name="send_auth_option" id="send_auth_option_email" value="email" onChange={this.onChange} />Send Me a Secure Code By Email
						</div>
						{ this.state.selectedOption === "email" ? <EmailBox email={this.state.user.email} /> : null}

					</div>

					<div className={classes.auth_box}>
						<div style={{cursor: "default"}} onclick="sendAuthOption(this)">
							<input type="radio" name="send_auth_option" id="send_auth_option_sms" value="sms" onChange={this.onChange} />Send Me a Secure Code By Cell Phone Text
						</div>
						{ this.state.selectedOption === "sms" ? <SmsBox phone={this.state.user.phone} /> : null }
	

					</div>
					<div className={classes.center}>
					<Button
						className={classes.button}
						color="primary"
						onClick={() => this.submitHandle(this.state.code)}
						variant="contained"
						disabled={this.state.disabledButton}
					>
						Verify Code
					</Button>
					</div>
				</form>
			</Paper>
		);
	}
}

export default withRouter(withStyles(styles)(TFASelection));
