
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, Button, TableRow, Select, TextField, Input, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { QuestionRow } from '../Components';
import { QuestionEditDialog } from '../Dialogs';
import { get, put } from '../Other/api';

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		overflowX: 'auto'
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120
	},
	formContainer: {
		paddingLeft: 15
	},
	button: {
		margin: theme.spacing.unit
	},
	table: {
		minWidth: 700
	},
	editCell: {
		width: 25,
		paddingRight: 10,
		paddingLeft: 10
	},
	controlContainer: {
		display: 'flex'
	},
	btnContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: 28,
		marginLeft: 'auto'
	}
});

class Recordings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchKey: 'recordings',
			searchString: 'false',
			questions: [],
			selectedQuestion: null,
			editDialogOpen: false,
			order: 'desc',
			orderBy: 1,
		};
	}

	openEditModal = question => () => {
		this.setState({ selectedQuestion: question, editDialogOpen: true });
	};
	handleSelectChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleTextChange = name => event => {
		this.setState({
			[name]: event.target.value,
		});
	};

	getQuestions = () => {
		let params = {};
		let { searchKey, searchString } = this.state;
		if (searchKey && searchString) {
			params[searchKey] = searchString;
		}
		get(
			"/questions",
			params,
			({ questions }) => this.setState({ questions }),
			() => { return this.props.isNotAuthenticated(); },
		);
	};

	handleClose = value => {
		this.setState({ selectedValue: value, editDialogOpen: false });
	};

	updateQuestionById = questionObj => {
		// We need to pull out a couple pieces of data to make the update happen more smoothly
		let { id, updated_at, created_at, ...data } = questionObj;
		put(
			`/questions/${id}`,
			data,
			() => this.setState(
				({ questions }) => ({
					questions: questions.map(q => (q.id === id) ? questionObj : q),
				}),
			),
		);
	};

	componentDidMount() {
		this.getQuestions();
	}

	// TODO
	// handleRequestSort = (event, property) => {
	//   let orderBy = property;
	//   let order = 'desc';

	//   if (this.state.orderBy === property && this.state.order === 'desc') {
	//     order = 'asc';
	//   }

	//   this.setState({ order, orderBy });
	// };

	render() {
		let { classes } = this.props;
		return (
			<Fragment>
				<div className={classes.controlContainer}>
					<div className={classes.formControl}>
						<FormControl className={classes.formControl}>
							<InputLabel htmlFor="searchKey-helper">filter by</InputLabel>
							<Select
								value={this.state.searchKey}
								onChange={this.handleSelectChange}
								input={<Input name="searchKey" id="searchKey-helper"/>}
							>
								<MenuItem value=""><em>None</em></MenuItem>
								<MenuItem value={'title'}>Title</MenuItem>
								<MenuItem value={'label'}>Label</MenuItem>
								<MenuItem value={'recordings'}>Recordings</MenuItem>
								<MenuItem value={'interviewID'}>Interview ID</MenuItem>
							</Select>
						</FormControl>
						{(this.state.searchKey === 'recordings'
								? <FormControl className={classes.formControl}>
									<InputLabel htmlFor="searchKey-helper">filter by</InputLabel>
									<Select
										value={this.state.searchString}
										onChange={this.handleSelectChange}
										input={<Input name="searchString" id="searchStringDropdown-helper"/>}
									>
										<MenuItem value={'false'}><em>None</em></MenuItem>
										<MenuItem value={'true'}>Recorded</MenuItem>
									</Select>
								</FormControl>
								: <FormControl>
									<TextField
										id="search-input"
										label=""
										className={classes.textField}
										type={this.state.searchKey === 'interviewID' ? 'number' : 'search'}
										onChange={this.handleTextChange('searchString')}
										autoComplete="current-search"
										margin="normal"
									/>
								</FormControl>
						)}
						<FormControl>
							<Button variant="outlined" color="primary" className={classes.button} onClick={this.getQuestions}> search </Button>
						</FormControl>
					</div>
					<div className={classes.btnContainer}>
						{/* <Button variant="fab" color="primary" aria-label="add" className={classes.button}>
                <FontAwesome name='plus' />
              </Button> */}
					</div>
				</div>

				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell>Edit</TableCell>
							{/* {['Title', 'Recording', 'Question Text', 'Labels', 'Default Client'].map( (col, index) => {
                  return (

                    <TableCell
                    key={`table_head_${index}`}
                    numeric={col === 'Default Client'}
                    sortDirection={this.state.order === index ? this.state.order : false}
                    >
                    <Tooltip
                      title='Sort'
                      placement='bottom-start'
                      enterDelay={300}
                      >
                      <TableSortLabel
                        active={this.state.order === index}
                        direction={this.state.order}
                        onClick={this.handleRequestSort(index)}
                        >
                        {col}
                      </TableSortLabel>
                    </Tooltip>
                    </TableCell>
                );
                })} */}
							<TableCell>Title</TableCell>
							<TableCell>Recording</TableCell>
							<TableCell>Question Text</TableCell>
							<TableCell>labels</TableCell>
							<TableCell>Default Client</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{this.state.questions.map(question =>
							<QuestionRow key={question.id} info={question} className={classes.editCell} openEditModal={this.openEditModal(question)}/>
						)}
					</TableBody>
				</Table>
				<QuestionEditDialog
					question={this.state.selectedQuestion}
					open={this.state.editDialogOpen}
					onClose={this.handleClose}
					update={this.updateQuestionById}
				/>
			</Fragment>
		);
	}
}

export default withStyles(styles)(Recordings);
