
import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	icon: {
		fontSize: 20,
		marginRight: theme.spacing.unit
	},
	enabledBtn: {
		textTransform: 'uppercase',
		color: theme.palette.primary.main
	},
	disabledBtn: {
		textTransform: 'uppercase',
		color: '#C4C4C4'
	}
});

class QuestionPreview extends Component {
	state = {
		play: true,
		audio: null
	};

	componentWillReceiveProps({ url }) {
		this.setState(({ audio }) => {
			if (audio && !audio.paused) {
				audio.pause();
			}
			let newAudio = new Audio(url);
			newAudio.onended = () => this.setState({ play: true });
			return { audio: newAudio };
		});
	}

	componentWillUnmount() {
		let { audio } = this.state;
		if (audio) {
			audio.pause();
		}
	}

	togglePlay = () => this.setState(
		({ play, audio }) => {
			play ? audio.play() : audio.pause();
			return { play: !play };
		},
	);

	render() {
		let {
			classes,
			disabled
		} = this.props;
		let { play } = this.state;
		return (
			<Button
				onClick={this.togglePlay}
				className={disabled ? classes.disabledBtn : classes.enabledBtn}
				disabled={disabled}
			>
				<FontAwesome
					name={play ? 'play-circle' : 'stop-circle'}
					className={classes.icon}
				/>
				{disabled ? 'Processing' : 'Preview Question'}
			</Button>
		);
	}
}

export default withStyles(styles)(QuestionPreview);
