
import React, { Component } from 'react';
import { TableCell, TableRow, IconButton, Menu, MenuItem } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import { EditUserDialog } from '../Dialogs';
import { put } from '../Other/api';

class ClientUserRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editDialogOpen: false,
			menuAnchorEl: null
		};
	}

	openMenu = ({ currentTarget }) => {
		this.setState({ menuAnchorEl: currentTarget });
	};

	closeMenu = () => {
		this.setState({ menuAnchorEl: null });
	};

	openEditModal = () => this.setState({ editDialogOpen: true });

	closeEditModal = () => this.setState({ editDialogOpen: false });

	onSave = () => {
		this.closeEditModal();
		this.props.refreshUsers();
	};

	setAsMainContact = () => {
		let { clientID, info: { id }} = this.props;
		put(`/clients/${clientID}`, { main_contact_user_id: id },
			() => { return this.closeMenu(); },
			() => { return this.closeMenu(); }
		);
	};

	render() {
		let { info, className } = this.props;
		let { menuAnchorEl, editDialogOpen } = this.state;
		let roles = info.roles.map(({ title }) => title).join(", ");
		
		return (
			<TableRow>
				<TableCell className={className}>
					<IconButton aria-label="Menu" onClick={this.openMenu}>
						<FontAwesome name="ellipsis-v"/>
					</IconButton>
					<Menu anchorEl={menuAnchorEl} open={!!menuAnchorEl} onClose={this.closeMenu}>
						<MenuItem onClick={this.openEditModal}>Edit</MenuItem>
						<MenuItem onClick={this.setAsMainContact}>Set as Main Contact</MenuItem>
					</Menu>
				</TableCell>
				<TableCell component="th" scope="row">
					{info.first_name} {info.last_name}
				</TableCell>
				<TableCell>{info.email}</TableCell>
				<TableCell>{roles}</TableCell>
				<TableCell>{info.id}</TableCell>
				{/*<TableCell>{info.api_key}</TableCell>*/}
				{editDialogOpen && (
					<EditUserDialog
						user={info}
						open
						onSave={this.onSave}
						onClose={this.closeEditModal}
						userID={info.id}
						userEmail={info.email}
						userPhone={info.phone}
						userRoles={info.roles}
						userFirstName={info.first_name}
						userLastName={info.last_name}
					/>
				)}
			</TableRow>
		);
	}
}

export default withRouter(ClientUserRow);
