
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Modal, Paper, Typography, Slide } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Loading, LoadingButton } from '../Components';
import { get, del } from '../Other/api';
import { to } from '../Other/utils';
import imgAccount from '../assets/images/account.svg';

function styles(theme) {
	return {
		modal: {
			alignItems: 'center',
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
			display: 'inline-block',
			flexDirection:'column',
			padding: '0',
			position: 'absolute',
			textAlign: 'center',
			width: '50%'
		},
		modalButtonDiv: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-end',
			margin: '0',
			maxWidth: '100%',
			padding: '0'
		},
		modalButtonWidth1: { 
			alignSelf: 'flex-end', 
			margin: `${theme.spacing.unit * 2}px`, 
			width: `calc(100% - ${theme.spacing.unit * 4}px)` 
		},
		modalButtonWidth2: { 
			alignSelf: 'flex-end', 
			fontSize: '12px', 
			margin: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px 0`, 
			maxWidth: `calc(50% - ${theme.spacing.unit * 4}px)` 
		},
		modalHr: { 
			margin: '0', 
			padding: '0', 
			width: 'calc(100% - 4px)' 
		},
		modalText: {
			margin: `${theme.spacing.unit * 2}px`
		},
		modalTitle: {
			margin: `${theme.spacing.unit * 2}px`
		},
		pageHeader: {
			display: 'block',
			justifyContent: 'space-between',
			margin: '10px 50px 10px 50px',
			width: 'calc(100% - 100px)'
		},
		paper1: {
			display: 'flex',
			flexDirection: 'column',
			left: '50%',
			margin: '0',
			padding: '0',
			position: 'absolute',
			transform: 'translate(-50%, 0)',
			width: '40%'
		},
		paper1Button: { 
			alignSelf: 'flex-end', 
			margin: `${theme.spacing.unit * 3}px` 
		},
		paper1Item: { 
			margin: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 3}px` 
		},
		paper2: {
			alignItems: 'flex-start',
			display: 'flex',
			flexDirection: 'row',
			left: '50%',
			position: 'absolute',
			transform: 'translate(-50%, 0)',
			width: '60%'
		},
		paper2Button: {
			alignSelf: 'flex-end', 
			margin: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px 0`
		},
		paper2Img: {
			alignSelf: 'stretch',
			maxHeight: `calc(100% - ${theme.spacing.unit * 4}px)`,
			maxWidth: `calc(100% - ${theme.spacing.unit * 4}px)`,
			padding: `${theme.spacing.unit * 2}px`
		},
		paper2Item: { 
			margin: `${theme.spacing.unit * 2}px 0 0 0` 
		},
		paperHr: { 
			margin: `${theme.spacing.unit * 2}px 0 0 0`, 
			width: '100%' 
		},
		paperDivLeft: {
			alignSelf: 'flex-start',
			margin: '0',
			width: '30%'
		},
		paperDivRight: {
			alignSelf: 'stretch',
			margin: `0 0 0 ${theme.spacing.unit * 2}px`,
			padding: `${theme.spacing.unit * 3}px`
		},
		root: {
			alignItems: 'center',
			boxSizing: 'border-box',
			height: 'calc(100% - 74px)',
			justifyContent: 'center'
		}
	};
}

function getModalStyle() {
    let left = 50, top = 50;
    return {
    	display: 'inline-block',
        left: `${left}%`,
        top: `${top}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

function getBlankModal() {
	return { buttons: [], open: false, text: '', title: '' };
}

function getBlankStatus() {
	return { action: null, data: '', title: 'Contact Support' };
}

// =============================================================== //
// ==================== AccountPage Component ==================== //
// =============================================================== //

class AccountPage extends Component {
    state = {
		actionLoading: true,
		client: {},
		loading: true,
		modal: getBlankModal(),
		status: getBlankStatus()
	};
    
    getAccountDetails = async () => {
        let [err, session] = await to(get(`/sessions`));
        if(err) {
			console.log(err);
		}
        if(session.status !== 'success') {
        	return false;
		}
		
        let [clientErr, clientInfo] = await to(get(`/clients/${session.user.client_id}`));
        if(clientErr) {
			console.log(clientErr);
		}
		
        if(clientInfo && clientInfo.client) {
        	if (!clientInfo.client.stripe_cid && clientInfo.client.status === "Standard") {
				clientInfo.client.status = "Trial";
			}
			this.setState({
				actionLoading: false,
				client: clientInfo.client,
				loading: false,
				status: this.getAccountStatus(clientInfo.client.status)
			});
		}
    };
    
    cancelAccount = async () => {
        let clientId = (this.state.client ? this.state.client.id : null);
        if(clientId) {
			let [err, data] = await to(del(`/clients/${clientId}/accounts`));
			if(data.status !== "success"){
				console.log(err, data);
			}
			this.setState({ actionLoading: true, modal: getBlankModal() });
			await this.getAccountDetails();
		}
		else {
        	console.log('we must have a client_id set to do stuff');
		}
    };
    
    getAccountStatus = (status) => {
		let newStatus = getBlankStatus();
		switch(status) {
			case 'Canceled':
			case 'Expired':
			case 'Trial':
				newStatus.action = (url) => { this.props.history.push(url); };
				newStatus.data = '/account/charge';
				newStatus.title = 'Upgrade Account';
				break;
			case 'Active':
			case 'Standard':
				newStatus.action = this.handleModal;
				newStatus.data = 'cancel';
				newStatus.title = 'Cancel Subscription';
				break;
			default:
		}
		return newStatus;
	};
    
    handleModal = (mode) => {
		let newModal = getBlankModal();
		switch(mode) {
			case 'cancel':
				newModal.buttons = [
					{ action: this.handleModal, data: null, title: 'I Want To Keep My Subscription' },
					{ action: this.cancelAccount, data: null, title: 'Confirm' },
				];
				newModal.open = true;
				newModal.text = `By selecting "Confirm" below, you will be cancelling the automatic renewal of your standard Dialogg account.\nYou will no longer have the ability to complete interviews after ${new Date(this.state.client.expire_date).toLocaleDateString()}.\nAre you sure that you want to continue?`;
				newModal.title = 'Confirm Renewal Cancellation';
				break;
			default:
		}
		this.setState({ modal: newModal });
	};
    
    statusClick = () => {
		if(this.state.status.action) {
			this.state.status.action(this.state.status.data);
		}
	};
    
// =============================================================== //
// ======================= Lifecycle Hooks ======================= //
// =============================================================== //
	
    componentDidMount() {
        this.getAccountDetails();
    }

    componentWillUnmount() {
        if(this.stripeHandler) {
            this.stripeHandler.close();
        }
    }

    render() {
        let { classes } = this.props;
        let { client, status } = this.state;
        client.expire_date = new Date(client.expire_date);
        let accountTitle = (client.status === 'Standard' ? `${client.status} Account` : `No-Renewal (${client.status})`);
        let contain = `${status.title}`.match('Upgrade');
        let dateTitle = (client.status === 'Standard' ? 'Renewal' : 'Expiration');
        let expired = (client.expire_date < (new Date()));
        let isStandardOrNotExpired = (client.status === 'Standard' || (client.status === 'Canceled' && !expired));
        
        return (
            <Fragment>
			{
                this.state.loading ? <Loading /> :
				<Slide direction="right" in={true} mountOnEnter unmountOnExit >
					<div className={classes.root}>
						<div className={classes.pageHeader}>
							<Typography variant="headline" align="left" gutterBottom className={classes.flexBottom} >
								Account
							</Typography>
						</div>
						{
							isStandardOrNotExpired ? 
							<Paper className={classes.paper1}>
								<Typography className={classes.paper1Item} variant="title"><b>Account Status: </b>{accountTitle}</Typography>
								<Typography className={classes.paper1Item} variant="title"><b>{dateTitle} Date: </b>{client.expire_date.toLocaleDateString()}</Typography>
								<Typography className={classes.paper1Item} variant="title"><b>Subscription Fee: </b>${client.price}</Typography>
								<hr className={classes.paperHr} />
								{
									this.state.actionLoading ? <LoadingButton height={20}/> :
									<Button color="primary" className={classes.paper1Button} disabled={!status.action || !client.price} onClick={this.statusClick} variant={contain ? 'contained' : 'outlined'} >
										{
											contain ? 
											<Typography style={{ color: 'white' }} variant="title" > {!client.price ? "Contact Support" : status.title} </Typography> :
											<Typography color="primary" variant="title" > {!client.price ? "Contact Support" : status.title} </Typography>
										}
									</Button>
								}
							</Paper> :
							<Paper className={classes.paper2}>
								<div className={classes.paperDivLeft}>
									<img className={classes.paper2Img} src={imgAccount} alt="Account" >
									</img>
								</div>
								<div className={classes.paperDivRight}>
									<Typography variant="title"><b>Your {client.status === 'Trial' ? 'Trial ' : ''}Subscription {expired ? 'Ended' : 'Ends'} {client.expire_date.toLocaleDateString()}</b></Typography>
									<Typography variant="title" >Upgrade Your Account Today!</Typography>
									<Typography className={classes.paper2Item} variant="title">${client.price}<span style={{ color: 'gray', fontSize: '14px' }} >/month</span></Typography>
									{
										this.state.actionLoading ? <LoadingButton height={20}/> :
										<Button color="primary" className={classes.paper2Button} disabled={!status.action || !client.price} onClick={this.statusClick} variant="contained" >
											<Typography style={{ color: 'white' }} variant="title" > {!client.price ? "Contact Support" : status.title} </Typography>
										</Button>
									}
								</div>
							</Paper>
						}
						<Modal
							aria-labelledby="simple-modal-title"
							aria-describedby="simple-modal-description"
							open={this.state.modal.open}
							>
								<div style={getModalStyle()} className={classes.modal}>
									<Typography align="center" className={classes.modalTitle} variant="title" gutterBottom >{this.state.modal.title}</Typography>
									<Typography align="left" className={classes.modalText} component="div" gutterBottom>
										{ this.state.modal.text.split('\n').map((text, t) => { return <p key={t}>{text}</p>; }) }
									</Typography>
									<hr className={classes.modalHr} />
									<div className={classes.modalButtonDiv} >
									{
										this.state.actionLoading ? <LoadingButton height={20}/> :
										this.state.modal.buttons.map((button, b) => {
											return (<Button className={classes[`modalButtonWidth${this.state.modal.buttons.length}`]} color="primary" key={`button_${b}`} onClick={() => { button.action(button.data); }} variant={button.title === 'Confirm' ? 'outlined' : 'contained'}>
														{button.title}
													</Button>)
										})
									}
									</div>
								</div>
						</Modal>
					</div>
				</Slide>
			}
            </Fragment>
        )
    }
}

export default withRouter(withStyles(styles)(AccountPage));
