
import React from 'react';
import loaderImg from '../assets/images/dribble-loader.gif';

const img = {
	position: 'absolute',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	textAlign: 'center',
	top: '50%',
	left: '50%',
	transform: `translate(-50%, -50%)`,
	width: 100,
	height: 75
};

export default () => (
	<img alt="processing request" style={img} src={loaderImg}/>
)
