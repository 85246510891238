
import React, { Component, Fragment } from 'react';
import { TableCell, TableRow, IconButton, Typography, Menu, MenuItem } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import FontAwesome from 'react-fontawesome';
import { EditClientForm } from '../Components';

const styles = theme => ({
	type: {
		display: 'inline'
	}
});

class ClientRow extends Component {
	state = {
		menuAnchorEl: null,
		formDialogOpen: false,
	};

	openMenu = ({ currentTarget }) => {
		this.setState({ menuAnchorEl: currentTarget });
	};

	closeMenu = () => {
		this.setState({ menuAnchorEl: null });
	};
	
	openFormDialog = () => {
		this.setState({ formDialogOpen: true });
	};

	closeFormDialog = () => {
		this.setState({ formDialogOpen: false });
	};

	saveClient = () => {
		this.closeFormDialog();
		this.props.onClientUpdate();
	};

	viewClientUsers = () => {
		this.props.history.push(`/clients/${this.props.info.id}/users`);
	};

	manageInterviews = () => {
		this.props.history.push(`/clients/${this.props.info.id}/interviews`);
	};

	render() {
		let { info, classes, className } = this.props;
		let { menuAnchorEl, formDialogOpen } = this.state;
		let created = new Date(info.created_at);
		let expires = new Date(info.expire_date);
		
		return (
			<TableRow>
				<TableCell className={className} onClick={this.handleClick}>
					<IconButton aria-label="Menu" onClick={this.openMenu}>
						<FontAwesome name="ellipsis-v"/>
					</IconButton>
					<Menu
						anchorEl={menuAnchorEl}
						open={!!menuAnchorEl}
						onClose={this.closeMenu}
					>
						<MenuItem onClick={this.viewClientUsers}>Users</MenuItem>
						<MenuItem onClick={this.manageInterviews}>Interviews</MenuItem>
						<MenuItem onClick={this.openFormDialog}>Settings</MenuItem>
					</Menu>
				</TableCell>
				<TableCell>{info.id}</TableCell>
				<TableCell component="th" scope="row">
					{info.name}
				</TableCell>
				<TableCell>
					<Fragment>
						{info.first_name} {info.last_name} &nbsp;
						<Typography variant="caption" className={classes.type}>
							id - {info.user_id}
						</Typography>
					</Fragment>
				</TableCell>
				<TableCell>{info.sales_rep}</TableCell>
				<TableCell>{info.status}</TableCell>
				<TableCell>{expires.toLocaleDateString()}</TableCell>
				<TableCell>{info.billing_option_name}</TableCell>
				<TableCell>{info.allow_invoice ? 'Yes' : 'No'}</TableCell>
				<TableCell>{info.is_rpo ? 'Yes' : 'No'}</TableCell>
				<TableCell>{created.toLocaleDateString()}</TableCell>
				{
					formDialogOpen && (
					<EditClientForm
						open
						onClose={this.closeFormDialog}
						onSave={this.saveClient}
						clientId={info.id}
						clientName={info.name}
						clientAddress={info.address}
						clientStatus={info.status}
						clientBillingOptionId={info.billing_option_id}
						clientAllowInvoice={info.allow_invoice ? 'Yes' : 'No'}
						isRPO={info.is_rpo ? 1 : 0}
						expireDate={info.expire_date}
						salesRepUserId={info.sales_rep_user_id}
						apiCode={info.api_key}
					/>)
				}
			</TableRow>
		)
	}
}

export default withRouter(withStyles(styles)(ClientRow));
