
import React, { Component } from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Modal, Chip, MenuItem, Typography, TextField, Paper, Button, FormControl, Input, FormHelperText, CircularProgress } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import { put, get } from '../Other/api';

const styles = theme => ({
	editUserModal: {
		position: 'absolute',
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing.unit * 4,
		top: `${50}%`,
		left: `${50}%`,
		width: theme.spacing.unit * 50,
		transform: `translate(-${50}%, -${50}%)`
	},
	input: {
		display: "flex",
		padding: 0
	},
	valueContainer: {
		display: "flex",
		flexWrap: "wrap",
		flex: 1,
		alignItems: "center"
	},
	chip: {
		margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
	},
	chipFocused: {
		backgroundColor: emphasize(
			theme.palette.type === "light"
				? theme.palette.grey[300]
				: theme.palette.grey[700],
			0.08
		)
	},
	noOptionsMessage: {
		padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
	},
	placeholder: {
		position: "absolute",
		left: 2,
		fontSize: 16
	},
	paper: {
		position: "absolute",
		zIndex: 1,
		marginTop: theme.spacing.unit,
		left: 0,
		right: 0
	},
	errorText: {
		marginBottom: theme.spacing.unit
	},
	divider: {
		height: theme.spacing.unit * 2
	},
	wrapper: {
		margin: theme.spacing.unit,
		position: 'relative'
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	}
});

const NoOptionsMessage = (props) => (
	<Typography
		color="textSecondary"
		className={props.selectProps.classes.noOptionsMessage}
		{...props.innerProps}
	>
		{props.children}
	</Typography>
);

const inputComponent = ({ inputRef, ...props }) => (
	<div ref={inputRef} {...props} />
);

const Control = (props) => (
	<TextField
		fullWidth
		InputProps={{
			inputComponent,
			inputProps: {
				className: props.selectProps.classes.input,
				inputRef: props.innerRef,
				children: props.children,
				...props.innerProps,
			},
		}}
		{...props.selectProps.textFieldProps}
	/>
);

const Option = (props) => (
	<MenuItem
		buttonRef={props.innerRef}
		selected={props.isFocused}
		component="div"
		style={{
			fontWeight: props.isSelected ? 500 : 400,
		}}
		{...props.innerProps}
	>
		{props.children}
	</MenuItem>
);

const Placeholder = (props) => (
	<Typography
		color="textSecondary"
		className={props.selectProps.classes.placeholder}
		{...props.innerProps}
	>
		{props.children}
	</Typography>
);

const ValueContainer = (props) => (
	<div className={props.selectProps.classes.valueContainer}>
		{props.children}
	</div>
);

const MultiValue = (props) => (
	<Chip
		tabIndex={-1}
		label={props.children}
		className={classNames(props.selectProps.classes.chip, {
			[props.selectProps.classes.chipFocused]: props.isFocused
		})}
		onDelete={props.removeProps.onClick}
		deleteIcon={<CancelIcon {...props.removeProps} />}
	/>
);

const Menu = (props) => (
	<Paper
		square
		className={props.selectProps.classes.paper}
		{...props.innerProps}
	>
		{props.children}
	</Paper>
);

const components = {
	Control,
	Menu,
	MultiValue,
	NoOptionsMessage,
	Option,
	Placeholder,
	ValueContainer,
};

class EditUserDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			processing: false,
			title: '',
			alt_text: '',
			labels: [],
			labelInput: '',
			roles: [],
			userRoles: [],
			firstName: "",
			lastName: "",
			phone: "",
			errors: [],
		};
	}

	handleClose = () => {
		this.props.onClose(this.props.selectedValue);
	};

	handleTextChange = name => event => {
		this.setState({
			[name]: event.target.value,
		});
	};

	setUserRoles = (userRoles) => this.setState({ userRoles });

	static getDerivedStateFromProps(nextProps, prevState) {
		return (nextProps.user && nextProps.user.id !== prevState.id) ? { ...nextProps.user } : null;
	}

	loadRoles = () => get(
		"/roles",
		null,
		({ roles }) => this.setState({
			roles: roles.map(({ code, title }) => ({ value: code, label: title })),
		}),
	);

	handleSubmit = (event) => {
		event.preventDefault();
		this.setState({ processing: true });
		let { userID, onSave } = this.props;
		let {
			phone,
			firstName,
			lastName,
			userRoles
		} = this.state;
		put(
			`/users/${userID}`,
			{
				firstName,
				lastName,
				phone,
				roles: userRoles.map(({ value }) => value)
			},
			() => {
				onSave();
				this.setState({
					errors: [],
					processing: false,
				});
			},
			(putErrors) => {
				let errors = {};
				putErrors.errors.forEach(({ param, msg }) => {
					errors[param] = errors[param] ? `${errors[param]} ${msg}` : msg;
				});
				this.setState({
					errors,
					processing: false,
				});
			},
		);
	};

	componentDidMount() {
		this.loadRoles();
		let {
			userPhone,
			userFirstName,
			userLastName,
			userRoles
		} = this.props;
		this.setState({
			phone: userPhone,
			firstName: userFirstName,
			lastName: userLastName,
			userRoles: userRoles.map(({ code, title }) => ({ value: code, label: title }))
		});
	}

	render() {
		let {
			classes,
			open,
			theme,
			userEmail
		} = this.props;
		let {
			roles,
			userRoles,
			firstName,
			lastName,
			phone,
			errors,
			processing
		} = this.state;
		let selectStyles = {
			input: base => ({
				...base,
				color: theme.palette.text.primary,
				"& input": {
					font: "inherit"
				}
			})
		};
		return (
			<Modal
				onClose={this.handleClose}
				className={classes.dialog}
				aria-labelledby="user-edit"
				aria-describedby="user-edit"
				open={open}
			>
				<div className={classes.editUserModal}>
					<Typography variant="display1" gutterBottom>
						Edit User
					</Typography>
					<FormControl fullWidth>
						<Input
							id="add_uset_email"
							fullWidth
							value={userEmail}
							disabled
						/>
						<FormHelperText error className={classes.errorText}/>
					</FormControl>
					<Select
						classes={classes}
						styles={selectStyles}
						textFieldProps={{
							label: "Roles",
							InputLabelProps: {
								shrink: true,
							},
						}}
						onChange={this.setUserRoles}
						fullWidth
						value={userRoles}
						options={roles}
						components={components}
						placeHolder="Roles"
						isMulti
					/>
					<div className={classes.divider}/>
					<form onSubmit={this.handleSubmit}>
						<FormControl fullWidth>
							<Input
								id="add_uset_firstName"
								placeholder="First Name"
								fullWidth
								value={firstName}
								error={!!errors['firstName']}
								onChange={this.handleTextChange('firstName')}
							/>
							<FormHelperText error className={classes.errorText}>{errors['firstName']}</FormHelperText>
						</FormControl>
						<FormControl fullWidth>
							<Input
								id="add_uset_lastName"
								placeholder="Last Name"
								fullWidth
								value={lastName}
								error={!!errors['lastName']}
								onChange={this.handleTextChange('lastName')}
							/>
							<FormHelperText error className={classes.errorText}>{errors['lastName']}</FormHelperText>
						</FormControl>
						<FormControl fullWidth>
							<Input
								id="add_uset_phone"
								placeholder="Phone"
								fullWidth
								value={phone}
								error={!!errors['phone']}
								onChange={this.handleTextChange('phone')}
							/>
							<FormHelperText error className={classes.errorText}>{errors['phone']}</FormHelperText>
						</FormControl>
						<div className={classes.wrapper}>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								disabled={processing}
								fullWidth
							>
								Save User
							</Button>
							{processing && <CircularProgress size={24} className={classes.buttonProgress}/>}
						</div>
					</form>
				</div>
			</Modal>
		);
	}
}

export default withStyles(styles, { withTheme: true })(EditUserDialog);
