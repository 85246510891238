
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Paper, TextField, Typography, Button } from '@material-ui/core';
import { post, get } from '../Other/api';

const styles = theme => ({
	container: {
		boxSizing: 'border-box',
		width: '100%',
		maxWidth: 600,
		margin: 'auto',
		marginTop: 50,
		padding: 30,
		flexGrow: 1
	},
	button: {
		marginTop: theme.spacing.unit * 3
	},
	forgot: {
		color: '#BDBDBD',
		cursor: 'pointer',
		font: 'Regular 12px/14px Work Sans',
		marginTop: theme.spacing.unit * 3,
		textAlign: 'center',
		textDecoration: 'underline',
		'&:hover': {
			color: '#2D64AA'
		}
	}
});

class TFAValidation extends Component {
	state = {
		code: '',
		showErrorMessage: false,
		disabledButton: ''
	};

	handleTextChange = (name) => {
		return (event) => {
			this.setState({ [name]: event.target.value });
		}
	};

	submitHandle = async (code) => {
		if (!code) {
			return false;
		}

		this.setState({"disableButton": "disabled" })
		let user_id = sessionStorage.getItem("user_id");
		post('/getToken/', { code, user_id },
			(result) => {
				// Set result token
				if (result.token) {
					sessionStorage.setItem("jwt_token", result.token);
					this.props.history.push('/');
				} else {
					
					// clear the code and show the error message.
					return false;
				}
				
			},
			(result) => {
				console.log(result);
				this.setState({"disableButton": "" })
				this.setState({ showErrorMessage: true});
			}
		);
	};
	
	render() {
		let { classes, authenticateHandle } = this.props;
		return (
			<Paper className={classes.container}>
				<form>
					<Typography variant="display1" gutterBottom>
						Two Step Verification
					</Typography>
					{this.state.showErrorMessage && (<Typography variant="h4" color="error">
						Incorrect Code, Please try again or go back to <a href="/login">Login</a>
					</Typography>)}
					<TextField
						fullWidth
						id="tfa-code-input"
						label="Authorization Code"
						margin="normal"
						onChange={this.handleTextChange('code')}
						onKeyUp={
                            (e) => {
                                return (e.key === 'Enter' 
                                    ? this.submitHandle(this.state.code) 
                                    : null);
                            }
                        }
						type="email"
					/>
					<Button
						className={classes.button}
						color="primary"
						fullWidth
						onClick={() => this.submitHandle(this.state.code)}
						variant="contained"
						disabled={this.state.disabledButton}
					>
						Verify Code
					</Button>
				</form>
			</Paper>
		);
	}
}

export default withRouter(withStyles(styles)(TFAValidation));
