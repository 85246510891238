
import React, { Component } from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button, Modal, Input, FormControl, FormHelperText, CircularProgress, TextField, MenuItem, Chip, Paper } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import { post, get } from '../Other/api';

const styles = theme => ({
	addUserModal: {
		position: 'absolute',
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing.unit * 4,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		top: `${50}%`,
		left: `${50}%`,
		width: theme.spacing.unit * 50,
		transform: `translate(-${50}%, -${50}%)`
	},
	errorText: {
		marginBottom: theme.spacing.unit
	},
	wrapper: {
		margin: theme.spacing.unit,
		position: 'relative'
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	},
	divider: {
		height: theme.spacing.unit * 2
	},
	input: {
		display: "flex",
		padding: 0
	},
	valueContainer: {
		display: "flex",
		flexWrap: "wrap",
		flex: 1,
		alignItems: "center"
	},
	chip: {
		margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
	},
	chipFocused: {
		backgroundColor: emphasize(
			theme.palette.type === "light"
				? theme.palette.grey[300]
				: theme.palette.grey[700],
			0.08
		)
	},
	noOptionsMessage: {
		padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
	},
	placeholder: {
		position: "absolute",
		left: 2,
		fontSize: 16
	},
	paper: {
		position: "absolute",
		zIndex: 1,
		marginTop: theme.spacing.unit,
		left: 0,
		right: 0
	}
});

const NoOptionsMessage = (props) => (
	<Typography
		color="textSecondary"
		className={props.selectProps.classes.noOptionsMessage}
		{...props.innerProps}
	>
		{props.children}
	</Typography>
);

const inputComponent = ({ inputRef, ...props }) => (
	<div ref={inputRef} {...props} />
);

const Control = (props) => (
	<TextField
		fullWidth
		InputProps={{
			inputComponent,
			inputProps: {
				className: props.selectProps.classes.input,
				inputRef: props.innerRef,
				children: props.children,
				...props.innerProps,
			},
		}}
		{...props.selectProps.textFieldProps}
	/>
);

const Option = (props) => (
	<MenuItem
		buttonRef={props.innerRef}
		selected={props.isFocused}
		component="div"
		style={{
			fontWeight: props.isSelected ? 500 : 400,
		}}
		{...props.innerProps}
	>
		{props.children}
	</MenuItem>
);

const Placeholder = (props) => (
	<Typography
		color="textSecondary"
		className={props.selectProps.classes.placeholder}
		{...props.innerProps}
	>
		{props.children}
	</Typography>
);

const ValueContainer = (props) => (
	<div className={props.selectProps.classes.valueContainer}>
		{props.children}
	</div>
);

const MultiValue = (props) => (
	<Chip
		tabIndex={-1}
		label={props.children}
		className={classNames(props.selectProps.classes.chip, {
			[props.selectProps.classes.chipFocused]: props.isFocused
		})}
		onDelete={props.removeProps.onClick}
		deleteIcon={<CancelIcon {...props.removeProps} />}
	/>
);

const Menu = (props) => (
	<Paper
		square
		className={props.selectProps.classes.paper}
		{...props.innerProps}
	>
		{props.children}
	</Paper>
);

const components = {
	Control,
	Menu,
	MultiValue,
	NoOptionsMessage,
	Option,
	Placeholder,
	ValueContainer,
};

class CreateUserDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clientId: this.props.clientId,
			open: this.props.open,
			firstName: null,
			lastName: null,
			email: null,
			phone: null,
			password: null,
			passwordMatch: null,
			errors: [],
			processing: false,
			roles: [],
			userRoles: [],
		};
	}

	setUserRoles = (userRoles) => this.setState({ userRoles });

	loadRoles = () => get(
		"/roles",
		null,
		({ roles }) => this.setState({
			roles: roles.map(({ code, title }) => ({ value: code, label: title })),
		}),
	);

	handleTextChange = name => event => {
		this.setState({
			[name]: event.target.value,
		});
	};

	handleModalClose = () => {
		this.setState({
			open: false,
			errors: [],
		});
		this.props.onClose();
	}

	handleSubmit = (event) => {
		event.preventDefault();
		this.setState({ processing: true });
		let {
			firstName,
			lastName,
			email,
			phone,
			password,
			passwordMatch,
			userRoles
		} = this.state;
		let { client, onClose } = this.props;
		let clientId = client ? client.id : null;
		let createUser = {
			clientId,
			firstName,
			lastName,
			email,
			phone,
			password,
			passwordMatch,
			roles: userRoles.map(({ value }) => value)
		};

		post(
			"/users",
			createUser,
			() => {
				onClose();
				this.setState({
					errors: [],
					processing: false,
				});
			},
			(postErrors) => {
				const errors = {};
				postErrors.errors.forEach(({ param, msg }) => {
					errors[param] = errors[param] ? `${errors[param]} ${msg}` : msg;
				});
				this.setState({
					errors,
					processing: false,
				});
			},
		);
	};

	componentDidMount() {
		this.loadRoles();
	}

	render() {
		let {
			classes,
			client,
			open,
			theme
		} = this.props;
		let {
			errors,
			processing,
			roles,
			userRoles
		} = this.state;
		let selectStyles = {
			input: base => ({
				...base,
				color: theme.palette.text.primary,
				"& input": {
					font: "inherit"
				}
			})
		};
		return (
			<Modal
				aria-labelledby="user-create"
				aria-describedby="user-create"
				open={open}
				onClose={this.handleModalClose}
			>
				<div className={classes.addUserModal}>
					<Typography variant="display1" gutterBottom>
						{(client && client.name)
							? `Add User To ${client.name}`
							: "Add User"
						}
					</Typography>
					<form onSubmit={this.handleSubmit}>
						<FormControl fullWidth>
							<Input
								id="add_uset_email"
								placeholder="Email"
								fullWidth
								error={!!errors['email']}
								onChange={this.handleTextChange('email')}
							/>
							<FormHelperText error className={classes.errorText}>{errors['email']}</FormHelperText>
						</FormControl>
						<Select
							classes={classes}
							styles={selectStyles}
							textFieldProps={{
								label: "Roles",
								InputLabelProps: {
									shrink: true,
								},
							}}
							onChange={this.setUserRoles}
							fullWidth
							value={userRoles}
							options={roles}
							components={components}
							placeHolder="Roles"
							isMulti
						/>
						<div className={classes.divider}/>
						<FormControl fullWidth>
							<Input
								id="add_uset_firstName"
								placeholder="First Name"
								fullWidth
								error={!!errors['firstName']}
								onChange={this.handleTextChange('firstName')}
							/>
							<FormHelperText error className={classes.errorText}>{errors['firstName']}</FormHelperText>
						</FormControl>
						<FormControl fullWidth>
							<Input
								id="add_uset_lastName"
								placeholder="Last Name"
								fullWidth
								error={!!errors['lastName']}
								onChange={this.handleTextChange('lastName')}
							/>
							<FormHelperText error className={classes.errorText}>{errors['lastName']}</FormHelperText>
						</FormControl>
						<FormControl fullWidth>
							<Input
								id="add_uset_phone"
								placeholder="Phone"
								fullWidth
								error={!!errors['phone']}
								onChange={this.handleTextChange('phone')}
							/>
							<FormHelperText error className={classes.errorText}>{errors['phone']}</FormHelperText>
						</FormControl>
						<FormControl fullWidth>
							<Input
								id="add_user_password"
								type="password"
								placeholder="Password"
								fullWidth
								error={!!errors['password']}
								onChange={this.handleTextChange('password')}
							/>
							<FormHelperText error className={classes.errorText}>{errors['password']}</FormHelperText>
						</FormControl>
						<FormControl fullWidth>
							<Input
								id="add_user_passwordMatch"
								type="password"
								placeholder="Re-type Password"
								fullWidth
								error={!!errors['passwordMatch']}
								onChange={this.handleTextChange('passwordMatch')}
							/>
							<FormHelperText error className={classes.errorText}>{errors['passwordMatch']}</FormHelperText>
						</FormControl>
						<div className={classes.wrapper}>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								disabled={processing}
								fullWidth
							>
								Add User
							</Button>
							{processing && <CircularProgress size={24} className={classes.buttonProgress}/>}
						</div>
					</form>
				</div>
			</Modal>
		)
	}
}

export default withStyles(styles, { withTheme: true })(CreateUserDialog);
