
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Delete, DragIndicator, AddCircle } from '@material-ui/icons';
import { Select, MenuItem, Paper, FormControl, Button, Typography, Grid, Divider } from '@material-ui/core';
import { QuestionsSearch, QuestionPreview } from './';
import { SortableHandle } from 'react-sortable-hoc';

const styles = theme => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		padding: theme.spacing.unit * 1,
		paddingTop: 0,
		paddingBottom: 0,
		paddingLeft: theme.spacing.unit * 4
	},
	questionContainer: {
		display: 'flex',
		flexDirection: 'row',
		padding: theme.spacing.unit * 2,
		paddingTop: 0,
		paddingBottom: 0
	},
	inactiveQuestionCard: {
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: '#E5F2FE'
		}
	},
	activeQuestionCard: {
		borderLeft: `${theme.spacing.unit / 2}px solid ${theme.palette.primary.main}`,
		marginTop: theme.spacing.unit / 4,
		marginBottom: theme.spacing.unit / 2
	},
	txt: {
		width: 'calc(100% - 200px)'
	},
	handle: {
		color: '#9D9D9D',
		backgroundColor: 'inherit',
		border: 'none',
		'&:hover': {
			cursor: 'move'
		},
		'&:active': {
			'&:hover': {
				outline: 'none',
				cursor: 'move'
			}
		}
	},
	lightGray: {
		color: '#9D9D9D'
	},
	redError: {
		color: '#E15142'
	},
	btnIcon: {
		marginRight: theme.spacing.unit
	},
	addQuestionBtn: {
		color: theme.palette.primary.main,
		float: 'left'
	},
	removeQuestionBtn: {
		color: '#969696',
		float: 'right'
	},
	questionRow: {
		padding: theme.spacing.unit * 2
	},
	questionsSearchContainer: {
		paddingLeft: theme.spacing.unit * 2,
		paddingRight: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit
	},
	handleIcon: {
		fontSize: 28
	}
});

const timeOptions = [
	{ text: 'Set Time Limit', time_limit: 0, disabled: true },
	{ text: '30 Second Answer', time_limit: 30, disabled: false },
	{ text: '1 Minute Answer', time_limit: 60, disabled: false },
	{ text: '3 Minute Answer', time_limit: 180, disabled: false },
	{ text: '5 Minute Answer', time_limit: 300, disabled: false }
];

const DragHandle = SortableHandle((props) => (
	<div onMouseDown={props.onMouseDown}>
		<button onMouseDown={props.hideStyles} tabIndex="-1" className={props.style}>
			<DragIndicator className={props.iconStyle}/>
		</button>
	</div>
));

class EditQuestionCard extends Component {
	state = {};

	handleTextChange = (order) => {
		return (event) => {
			this.props.handleTextChange(order, event.target.value);
		};
	};

	handleTimeLimitChange = (order) => {
		return (event) => {
			this.props.handleTimeLimitChange(order, event.target.value);
		};
	};

	addQuestion = () => {
		this.props.addQuestion(this.props.order);
	};

	handleSearch = (order) => {
		return (event) => {
			console.log(order, event);
		};
	};

	removeQuestion = () => {
		this.props.removeQuestion(this.props.order);
	};

	render() {
		let { classes, order, isActive, title, dragging, recording_url, setQuestion, time_limit, errorsVisible, currentQuestionIds } = this.props;
		let timeLimitErr = !time_limit && errorsVisible;
		let titleErr = !title && errorsVisible;
		let div = document.querySelector('body > div:last-child');
		if (div) {
			div.style.boxShadow = (dragging === false ? '' : '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)');
		}
		
		return (
			<Paper className={isActive ? classes.activeQuestionCard : classes.inactiveQuestionCard} elevation={isActive ? 2 : 0}>
				<Grid container justify="space-between" className={classes.questionRow} alignItems="flex-start" wrap="nowrap">
					<Grid item>
						<Grid container alignItems="flex-start" wrap="nowrap">
							<Grid item>
								<DragHandle style={classes.handle} iconStyle={classes.handleIcon}/>
							</Grid>
							<Grid item>
								<Typography className={classes.lightGray} variant="headline">{order + 1}.</Typography>
							</Grid>
							<Grid item zeroMinWidth>
								<Typography className={classes.questionContainer} variant="headline">
									{title || <span className={titleErr ? classes.redError : classes.lightGray}>(Fill Out Question Form)</span>}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<FormControl className={classes.container}>
							<Select
								name="time_limit"
								value={time_limit}
								error={timeLimitErr}
								className={classes.lightGray}
								disableUnderline={!timeLimitErr}
								onChange={this.handleTimeLimitChange(order)}
							>
							{
								timeOptions.map((time) => {
									return <MenuItem key={time.time_limit} value={time.time_limit} disabled={time.disabled}>{time.text}</MenuItem>;
								})
							}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
				{isActive && (
					<Fragment>
						{!title && 
							<div className={classes.questionsSearchContainer}>
								<QuestionsSearch setQuestion={(text, id) => setQuestion(order, text, id)} skipIds={currentQuestionIds}/>
							</div>
						}
						<Divider/>
						
						{/* Toolbar */}
						<Grid container justify="space-between">
							<Grid item>
								<QuestionPreview url={recording_url} disabled={!recording_url}/>
							</Grid>
							<Grid item>
								<Button onClick={this.addQuestion} className={classes.addQuestionBtn}>
									<AddCircle color="primary" className={classes.btnIcon}/>
									Add Question
								</Button>
								<Button onClick={this.removeQuestion} className={classes.removeQuestionBtn}>
									<Delete className={classes.btnIcon}/>
									Delete
								</Button>
							</Grid>
						</Grid>
					</Fragment>
				)}
			</Paper>
		);
	}
}

export default withStyles(styles)(EditQuestionCard);
