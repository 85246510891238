
import React from "react";
import Slider from "react-slick";
import '../statics/slider.css';

export default class HOCSlider extends React.Component {
	render() {
		let settings = {
			dots: true,
			arrows: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1
		};
		return (
			<Slider {...settings}>
				{this.props.children}
			</Slider>
		);
	}
}
