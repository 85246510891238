
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Paper, TextField, Typography, Button } from '@material-ui/core';

const styles = theme => ({
	container: {
		boxSizing: 'border-box',
		width: '100%',
		maxWidth: 600,
		margin: 'auto',
		marginTop: 50,
		padding: 30,
		flexGrow: 1
	},
	button: {
		marginTop: theme.spacing.unit * 3
	},
	forgot: {
		color: '#BDBDBD',
		cursor: 'pointer',
		font: 'Regular 12px/14px Work Sans',
		marginTop: theme.spacing.unit * 3,
		textAlign: 'center',
		textDecoration: 'underline',
		'&:hover': {
			color: '#2D64AA'
		}
	}
});

class Login extends Component {
	state = {
		password: '',
		email: '',
		disabledButton: ''
	};

	handleTextChange = (name) => {
		return (event) => {
			this.setState({ [name]: event.target.value });
		}
	};
	
	// The submit handler takes a function from its parent (App) because it needs to set the 
	// authenticated state. However after the parent function is called, it needs to route validate
	// tfa.
	submitHandler = (email, password) => {
		this.setState({"disabledButton": "disabled"});
		this.props.authenticateHandle(email, password).then((result) => {
			let loginResult = JSON.parse(sessionStorage.getItem("login_result"));
			if (!result || !loginResult) {
				this.setState({"disabledButton": ""});
				return;
			}

			// route to /validate if the login was successful
			if (!loginResult.tfa && loginResult.token) {
				sessionStorage.setItem("jwt_token", loginResult.token);
				this.props.history.push('/');
			} else {
				this.props.history.push('/tfa_select');
			}
		});
	}
	render() {
		let { classes } = this.props;
		return (
			<Paper className={classes.container}>
				<form>
					<Typography variant="display1" gutterBottom>
						Log In
					</Typography>
					<TextField
						fullWidth
						id="email-input"
						label="Email"
						margin="normal"
						onChange={this.handleTextChange('email')}
						onKeyUp={(e) => { return (e.key === 'Enter' ? this.submitHandler(this.state.email, this.state.password) : null); }}
						type="email"
					/>
					<TextField
						fullWidth
						id="password-input"
						label="Password"
						margin="normal"
						onChange={this.handleTextChange('password')}
						onKeyUp={(e) => { return (e.key === 'Enter' ? this.submitHandler(this.state.email, this.state.password) : null); }}
						type="password"
					/>
					<Button
						className={classes.button}
						color="primary"
						fullWidth
						onClick={() => this.submitHandler(this.state.email, this.state.password)}
						variant="contained"
						disabled={this.state.disabledButton}
					>
						Login
					</Button>
					<Link to="/forgot">
						<Typography className={classes.forgot} gutterBottom variant="subheading">Forget Password?</Typography>
					</Link>
				</form>
			</Paper>
		);
	}
}

export default withRouter(withStyles(styles)(Login));
